import React from "react";

function UserIcon({w,h}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#000"
        d="M10.833 30v-.15c0-1.442 0-2.765.145-3.84.158-1.185.533-2.438 1.563-3.467 1.03-1.031 2.284-1.406 3.467-1.566 1.077-.144 2.4-.144 3.843-.144h.297c1.443 0 2.767 0 3.842.145 1.185.159 2.438.534 3.466 1.564 1.032 1.03 1.407 2.283 1.567 3.466.142 1.062.143 2.36.143 3.779 4.289-.917 7.5-4.687 7.5-9.2 0-4.117-2.678-7.62-6.408-8.895-.53-4.702-4.567-8.359-9.465-8.359-5.26 0-9.523 4.214-9.523 9.412 0 1.15.208 2.25.59 3.27a7.266 7.266 0 00-1.384-.133c-3.945.001-7.143 3.161-7.143 7.06C3.333 26.84 6.531 30 10.476 30h.357z"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M20 23.333c-3.144 0-4.714 0-5.69.977-.977.977-.977 2.547-.977 5.69 0 3.143 0 4.713.977 5.69.976.977 2.546.977 5.69.977 3.143 0 4.713 0 5.69-.977.976-.977.976-2.547.976-5.69 0-3.143 0-4.713-.976-5.69-.977-.977-2.547-.977-5.69-.977zm3.008 5.14l-2.223-2.221a1.112 1.112 0 00-1.57 0l-2.224 2.221a1.111 1.111 0 101.572 1.572l.325-.325v3.243a1.111 1.111 0 102.223 0V29.72l.325.325a1.111 1.111 0 001.572-1.572z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UserIcon;