import React, { useEffect, useState } from 'react'
import Picture from './Tools/Image/Picture'
import Button from './Tools/Button/Button'
import Text from './Tools/Text/Text'
import HTML from './Tools/HTML/HTML'
import { useDrop } from 'react-dnd'
import Items from '../../Components'
import { useSelector } from 'react-redux'
import { Upload } from '@mui/icons-material'

const BlogBody = ({body,setbody,upblog}) => {
  let uelems = useSelector(state=> state.blog)

useEffect(() => {
  console.log(uelems)
}, [uelems])
  
  
    const [{isOver}, drop] = useDrop(
        () => ({
          accept: Items.TOOL,
          drop: () => setbody(''),
          collect: monitor => ({
            isOver: !!monitor.isOver(),
          }),
        }),[])
  return (
    <div className='grid grid-cols-12 [&>*]:m-1 mt-5'>
        <div className="col-span-11">
            <span className='text-sm text-white'>Blog Body</span>
            <div className="rounded-xl bg-dashboard-dark-black min-h-full mt-3 p-3 text-white" style={{opacity:isOver?0.5:1}} ref={drop}>
                {Array.from(uelems).map((v)=>v.compo) }
            </div>
        </div>
        <div className="col-span-1 rounded-xl bg-dashboard-dark-black flex flex-col items-center justify-center text-white py-5 [&>*]:mt-7">
            <span className='text-white text-sm'>Tools</span>
            <Picture/>
            <Text/>
            <Button/>
            <HTML/>
        </div>
        <div className="col-span-12 pt-10">
          <button className="px-5 py-2 bg-theme-orange font-bold transition-all ease-in-out duration-300 hover:bg-opacity-80 text-dashboard-dark-black rounded-full" onClick={upblog}> <Upload/> Publish</button>
        </div>
    </div>
  )
}

export default BlogBody