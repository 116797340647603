import React from 'react'
import { useSelector } from 'react-redux'

const ContentNav = () => {
  let title = useSelector(state=> state.dashboard.title)
  
  return (
    <div className='w-full px-3 py-5 fixed z-20 bg-dashboard-black bg-opacity-30 backdrop-blur-3xl'>
        <p className='text-white mx-3 text-xl'>{title}</p>
    </div>
  )
}

export default ContentNav