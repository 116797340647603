import { createSlice } from "@reduxjs/toolkit";

export const accessSlice = createSlice({
    name:'access',
    initialState:{
        codes:[]
    },
    reducers:{
        addCode:(state,action)=>{
            state.codes.push(action.payload)
        },
        setCode:(state,action)=>{
            if(Array.isArray(action.payload)){
                state.codes = action.payload
            }
            else{
                console.log('Not an array')
            }
        }
    }
})
export default accessSlice.reducer
export const {addCode,setCode} = accessSlice.actions