import ArrowBack from '../../../icons/ArrowIcon'
import React from 'react'

const LinkItem = ({img,title,subOne,subTwo,link}) => {
  return (
    <div className='m-2 rounded-2xl bg-dashboard-dark-black p-3 grid grid-cols-10 py-5'>
        <div className="col-span-2 flex items-center justify-center">
            <img src={img} alt={title} className='px-6'/>
        </div>
        <div className="col-span-6 flex justify-center flex-col">
            <p className='md:text-lg text-white'>{title}</p>
            <p className='text-xs text-white'> <span className="text-theme-orange">{subOne}</span> {subTwo}</p>
        </div>
        <div className="col-span-2 flex items-center justify-center">
            <a href={link} target="_blank" rel='noreferrer'>
            <button className='bg-theme-orange transition-all ease-in-out duration-200 hover:bg-opacity-90 px-5 py-1.5 rounded-full'>
                <ArrowBack h={25}/>
            </button>
            </a>
        </div>
    </div>
  )
}

export default LinkItem