
import { useEffect, useState } from 'react';
import './App.css';
import DashBoard from './Components/DashBoard/DashBoard';
import LoginPage from './Components/LoginPage/LoginPage';
import Preloader from './Components/Preloader/Preloader';
import mainAuth from './firebase';
import { ThemeProvider } from '@emotion/react';
import theme from './theme/muiTheme'

function App() {
  const [loaded, setloaded] = useState(false)
  const [auth, setauth] = useState(true)
  const [uid, setuid] = useState('')
  useEffect(() => {
    setTimeout(() => {
    if(mainAuth.currentUser!=null){
      setauth(true)
      setuid(mainAuth.currentUser.uid)
      setloaded(true)
    }
    else{
      setauth(false)
      setloaded(true)
    }
    }, 2000);
  }, [])
  
  return (
    <div  className='bg-dashboard-black'>
      <Preloader active={!loaded}/>
      {auth? <DashBoard/>: <LoginPage setauth={setauth} auth={auth} setuid={setuid}/>}
    </div>
  );
}

export default App;
