import React from 'react'
import GoogleConsole from '../../icons/GoogleConsole'
import AdsecQR from '../../icons/AdsecQR'
const GoogleAdmin = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2'>
      <div className="py-10 md:px-20">
        <div className="flex items-center">
          <GoogleConsole h={80}/>
          <div className="mx-5">
          <p className='text-white font-semibold'>Google Workspace</p>
          <p className="text-white font-medium text-2xl">Admin Console</p>
          </div>
         
        </div>
        <div className="rounded-xl p-7 flex flex-col items-center justify-center bg-dashboard-dark-black bg-opacity-60 text-sm mt-32">
            <p className="text-white text-center mx-3">To login to your Google Admin Console ,you have to use your following mail account to login.</p>
            <div className="rounded-full bg-dashboard-black text-theme-orange px-5 py-2 mt-5">admin@adsecbd.com</div>
         </div>
      </div>
      <div className="flex items-center flex-col justify-center">
        <AdsecQR h={200}/>
        <a href="https://admin.google.com/u/1/ac/home?hl=en&rapt=AEjHL4OLfCyULzfMg3DBEWQAvzMfPGlO6GBVMzoBh24clN9u12-w-q4g28ALBDhaIXAdLBe3blsVcbbDcGG5O-E2hA2zKG8BtA" target="_blank" rel="noreferrer"><button className='px-5 py-2 bg-theme-orange font-semibold mt-5 rounded-full text-sm'>Go to Google Admin Console</button></a> 
      </div>
    </div>
  )
}

export default GoogleAdmin