import React from "react";

function Upload({h,w,selected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 45 45"
    >
     <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
      <path
        className="transition-all duration-300 ease-in-out" fill={selected?"#313131":"#FFF27C"}
        d="M21.75 25.531V29.5c0 .354.12.65.36.89s.536.36.89.36.651-.12.891-.36.359-.536.359-.89v-3.969l1.125 1.125c.125.125.266.219.422.282.156.062.313.088.47.077a1.296 1.296 0 00.859-.39c.228-.25.348-.542.359-.875.01-.333-.11-.625-.36-.875l-3.25-3.25a1.104 1.104 0 00-.406-.266A1.393 1.393 0 0023 21.28c-.167 0-.323.026-.469.078a1.104 1.104 0 00-.406.266l-3.25 3.25c-.25.25-.37.542-.359.875.01.333.14.625.39.875.25.23.542.35.875.36.334.01.625-.11.875-.36l1.094-1.094zM15.5 34.5a2.407 2.407 0 01-1.765-.734A2.408 2.408 0 0113 32V12c0-.688.245-1.276.735-1.766A2.406 2.406 0 0115.5 9.5h8.969a2.473 2.473 0 011.75.719l6.062 6.062a2.473 2.473 0 01.719 1.75V32a2.41 2.41 0 01-.734 1.766 2.41 2.41 0 01-1.766.734h-15zM24.25 17c0 .354.12.65.36.89s.536.36.89.36h5L24.25 12v5z"
      ></path>
    </svg>
  );
}

export default Upload;
