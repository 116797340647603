import React from 'react'
import { useDispatch } from 'react-redux'
import { setdashboard } from '../../../app/slices/dashboardSlice'
import AutoCert from '../AutoCert'
import { ArrowBack } from '@mui/icons-material'

const SingleCertificate = () => {
    const dis = useDispatch()
  return (
    <div>
        <button className='text-dashboard-black bg-theme-orange rounded-lg px-2 py-1' onClick={e=>dis(setdashboard({title:'Auto Certificate',component:<AutoCert/>}))}>
            <ArrowBack sx={{fontSize:20}}/>
        </button>
    </div>
  )
}

export default SingleCertificate