import React from 'react'
import ProfilePage from '../../ProfilePage/ProfilePage'
import { useSelector } from 'react-redux'
import fire from '../../../firebase'
const ProfileBox = ({setselected}) => {
  const user = useSelector(state=> state.user)
  const logOut = ()=>{
    fire.signOut().then(()=>{
      if(typeof window!=="undefined"){
        window.location.reload()
      }
      else{
        alert('Please refresh the page first')
      }
    }).catch(err=>{
      console.log(err)
      alert('Error! check console')
    })
  }
  return (
    <div className='ring-1 ring-theme-orange border-md m-2 p-3 rounded-xl bg-theme-black w-52 fixed bottom-0 left-0'>
        <div className="flex items-center">
          <div className="h-10 w-10 overflow-hidden p-0.5">
            <img src={user?user.photo:"https://i2-prod.mirror.co.uk/incoming/article29508499.ece/ALTERNATES/s1200c/0_Lionel-Messi-29.jpg"} className='object-cover object-center h-full w-full rounded-full ring-1 ring-theme-orange' alt='Profle'/>
            </div>
            <h1 className='text-white text-sm font-semibold mx-3'>{user&&user.userName}</h1>
        </div>
        <p className="text-xs text-gray-500 my-3">{user&& user.position}</p>
        <div className="flex w-full">
            <button className="flex-1 mx-1 ring-1 ring-theme-orange text-theme-orange px-5 py-2 rounded-full text-xs" onClick={logOut}>Logout</button>
            <button className="flex-1 mx-1 px-5 py-2 rounded-full bg-theme-orange text-xs" onClick={e=> setselected({title:'Profile',component:<ProfilePage/>})}>Profile</button>
        </div>
    </div>
  )
}

export default ProfileBox