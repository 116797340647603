import React from 'react'
import EmailIcon from '../../../icons/EmailIcon'

const EmailBox = ({email,phone}) => {
  return (
    <div className='p-4 rounded-lg bg-theme-black flex items-center justify-center h-16'>
        <EmailIcon w={25}/>
        <p className='text-white font-light italic text-sm mx-7 '>{email}</p>
        <div className="rounded-full bg-dashboard-black px-5 py-1 text-sm text-gray-400 font-light">{phone}</div>
    </div>
  )
}

export default EmailBox