import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import Menu from '../Menu/Menu'
import ContentContainer from '../ContentContainer/ContentContainer'
import auth from '../../firebase'
import axios from 'axios'
import url from '../../apiurl'
import { useDispatch } from 'react-redux'
import { setemail, setphone, setphoto, setposition, setuid, setusername } from '../../app/slices/userSlice'
import { setCode } from '../../app/slices/accessSlice'

const DashBoard = () => {
  const dis = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      if(auth.currentUser!=null){
        auth.currentUser.getIdToken(true).then((token)=>{
          axios.get(`${url}/user/${auth.currentUser.uid}`,{
            headers:{
              'Authorization':token
            }
          }).then((res)=>{
            if(res.status===200){
              // setuser(res.data.result[0])
              axios.get(`${url}/user/access/${auth.currentUser.uid}`,{
                headers:{
                  'Authorization':token
                }
              }).then((resacc)=>{
                if(resacc.status===200){
                  // setaccess(resacc.data.result)
                // setaccess(resacc.data.result)
                  dis(setCode(resacc.data.result))
                }
              })
              const user = res.data.result[0]
              dis(setphoto(user.photo))
              dis(setusername(user.userName))
              dis(setuid(user.uid))
              dis(setposition(user.position))
              dis(setemail(user.email))
              dis(setphone(user.phone))
            }
            console.log(res.data)
          }).catch((err)=>{
            console.log(err)
          })
        }).catch((err)=> {
          alert('Error! Please check the console')
          console.log(err)
        })
      }
    }, 1000);
  
  }, [])
  
  return (
    <>
    <Grid container>
        <Grid item xs={12} lg={2}>
          <Menu/>
        </Grid>
        <Grid item xs={12} lg={10}>
          <ContentContainer/>
        </Grid>
      </Grid>
    </>
  )
}

export default DashBoard