import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editElement, removeElement } from '../../../../../../app/slices/blogSlice'
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ButtonGroup } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'

const HTMLCompo = ({id,html}) => {
    const dis = useDispatch()
    const [del, setdel] = useState(false)
    const [edit, setedit] = useState(false)
    const [htmlp, sethtmlp] = useState(html)
    let blogs = useSelector(state=> state.blog)
    const removeElem = ()=>{
        const index = Array.from(blogs).map(v=> v.id).indexOf(id)
        dis(removeElement(index))
        setdel(false)
    }
    const saveChange = ()=>{
        const index  = Array.from(blogs).map(v=> v.id).indexOf(id)
        dis(editElement({index,obj:{id,html,code:704,compo:<HTMLCompo id={id} html={htmlp}/>}}))
        setedit(false)
    }
  return (
    <div className='p-3 m-2 rounded-xl bg-dashboard-black flex flex-col items-center'>
         <div dangerouslySetInnerHTML={{__html:html}}></div>
        <div className="flex w-full justify-end">
          <IconButton onClick={e=> setedit(true)}>
            <Edit sx={{color:'#FFF27C'}}/>
          </IconButton>
          <IconButton onClick={e=> setdel(true)}>
            <Delete sx={{color:'#FFF27C'}}/>
          </IconButton>
        </div>
        <Dialog open={del} onClose={e=> setdel(false)} aria-labelledby="Delete Dialog">
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Want To Remove?
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <p className='my-2 text-white text-sm'>Press the remove button to remove the HTML element</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
            <ButtonGroup variant="contained" size="small" aria-label="">
              <Button color="error" onClick={removeElem}>Remove</Button>
              <Button color="success" onClick={e=> setdel(false)}>Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        <Dialog open={edit} fullWidth aria-labelledby="Edit dialog">
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Edit HTML <Edit/>
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <textarea className='ring-1 ring-theme-orange rounded-xl p-3 bg-transparent w-full mt-4 text-white' value={htmlp} onChange={e=> sethtmlp(e.target.value)} rows="10"></textarea>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
            <ButtonGroup variant="contained" size="small">
              <Button color="success" onClick={saveChange}>Save</Button>
              <Button color="error" onClick={e=> setedit(false)}>Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default HTMLCompo