import React, { useEffect, useState } from 'react'
import BlogTop from './Children/BlogTop/BlogTop'
import BlogBody from './Children/BlogBody/BlogBody'
import { useDispatch, useSelector } from 'react-redux'
import auth from '../../firebase'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import BlogPage from '../BlogPage/BlogPage'
import axios from 'axios'
import url from '../../apiurl'
import { Celebration } from '@mui/icons-material'
import { setdashboard } from '../../app/slices/dashboardSlice'
import { clearblogs } from '../../app/slices/blogSlice'
import { uid } from 'uid'

const UploadBlog = () => {
  const [title, settitle] = useState('')
  const [body, setbody] = useState('')
  const [feat, setfeat] = useState('')
  let blogs = useSelector(state=> state.blog)
  const dis = useDispatch()
  const [bind, setbind] = useState([])
  const [err, seterr] = useState(false)
  const [succ, setsucc] = useState(false)
  const [succt, setsucct] = useState('')
  const [errt, seterrt] = useState('')
  const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  useEffect(() => {
    const arr = []
    Array.from(blogs).map(v=> {
      arr.push({code:v.code,html:v.html})
    })
    setbind(arr)
  }, [blogs]) 
  const uploadBlog = ()=>{
    const date = new Date()
    if(feat.length<2){
      seterr(true)
      seterrt('Upload a featured image first')
    }
    else if(title.length<3){
      seterr(true)
      seterrt('Set the title first')
    }
    else if(bind.length<1){
      seterr(true)
      seterrt('Make the body first')
    }
    else{
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.post(`${url}/blogs/`,{
          date:`${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()} | ${date.toLocaleTimeString()}`,
          title,
          state:298,
          featured:feat,
          body:bind,
          slug:uid(22)
        },{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status===200){
            setsucct('Upload Successful! Check manage blogs section')
            setsucc(true)
          }
        })
      }).catch((err)=> console.log(err))
      
    }
    
  }
  const manage = ()=>{
    settitle('')
    setfeat('')
    dis(clearblogs())
    dis(setdashboard({title:'Manage Blogs',component:<BlogPage/>}))
  }
  return (
    <div>
      {/* <Draggable isDragging={true} text="Kire baal"/> */}
      <BlogTop top={title} settop={settitle} feat={feat} setfeat={setfeat}/>
      <BlogBody body={body} setbody={setbody} upblog={uploadBlog}/>
      <Dialog open={err} onClose={e=> seterr(false)}>
        <DialogTitle className='bg-dashboard-black text-theme-orange'>
          Please do it First!
        </DialogTitle>
        <DialogContent className='bg-dashboard-dark-black'>
          <DialogContentText>
            <p className='my-5 text-white text-sm'>{errt}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='bg-dashboard-dark-black'>
          <Button variant="contained" color="error" size="small" onClick={e=> seterr(false)}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={succ} onClose={e=> setsucc(false)}>
        <DialogTitle className='bg-dashboard-black text-theme-orange'>
          Success <Celebration/>
        </DialogTitle>
        <DialogContent className='bg-dashboard-dark-black'>
          <DialogContentText>
            <p className="mt-5 text-sm text-white">{succt}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='bg-dashboard-dark-black'>
          <Button variant="contained" color="success" size="small" onClick={manage}>
            Manage!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UploadBlog