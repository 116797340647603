import React from 'react'
import InitialContainer from './InitialContainer/InitialContainer'

const AutoCert = () => {
  return (
    <div>
      <InitialContainer/>
    </div>
  )
}

export default AutoCert