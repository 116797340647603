import React from "react";

function Icon({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
    <path
      fill={selected?"#313131":"#FFF27C"} className="transition-all duration-300 ease-in-out"
      d="M16.75 25.75c1.042 0 1.927-.365 2.656-1.094.73-.729 1.094-1.614 1.094-2.656s-.365-1.927-1.094-2.656c-.729-.73-1.614-1.094-2.656-1.094s-1.927.365-2.656 1.094C13.364 20.073 13 20.958 13 22s.365 1.927 1.094 2.656c.729.73 1.614 1.094 2.656 1.094zm0 3.75c-2.083 0-3.854-.73-5.313-2.188C9.98 25.855 9.25 24.084 9.25 22s.73-3.854 2.188-5.313c1.458-1.458 3.229-2.187 5.312-2.187 1.688 0 3.162.48 4.422 1.438 1.26.958 2.14 2.145 2.64 3.562h10.47l2.468 2.469-4.375 5L29.25 24.5l-2.5 2.5-2.5-2.5h-.438a7.262 7.262 0 01-2.718 3.625c-1.292.917-2.74 1.375-4.344 1.375z"
    ></path>
  </svg>
  );
}

export default Icon;
