import React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import { Archive, Delete, Newspaper } from '@mui/icons-material'
import ArchivedBlogs from '../ArchivedBlogs/ArchivedBlogs'
import DeletedBlogs from '../DeletedBlogs/DeletedBlogs'
import BlogContainer from '../BlogContainer/BlogContainer'
import { Box, Tab, Tabs } from '@mui/material'
const TopPart = ({setcompo,compo}) => {
  
  return (
    <div className='w-full flex items-center justify-end md:px-10'>
        {/* <ButtonGroup variant="contained" color="primary" size='small' aria-label="">
          <Button onClick={e=> setcompo(<BlogContainer/>)} sx={{display:'flex',alignItems:'center'}}><Newspaper sx={{mr:1}}/> Active </Button>
          <Button onClick={e=> setcompo(<ArchivedBlogs/>)}><Archive sx={{mr:1}}/> Archived</Button>
          <Button onClick={e=> setcompo(<DeletedBlogs/>)}><Delete sx={{mr:1}}/> Recycle Bin</Button>
        </ButtonGroup> */}
        <Box sx={{ width: '100%', bgcolor: '#FFF27C',borderRadius:2,mb:5,display:'flex',justifyContent:'center',color:'#FFF27C' }}>
          <Tabs
            value={compo}
            onChange={(e,val)=> setcompo(val)}>
            <Tab icon={<Newspaper/>} value={<BlogContainer/>} label={<p className='normal-case font-semibold text-dashboard-black'>Active Blogs</p>}/>
            <Tab icon={<Archive/>} value={<ArchivedBlogs/>} label={<p className='normal-case font-semibold text-dashboard-black'>Archived Blogs</p>} />
            <Tab icon={<Delete/>} value={<DeletedBlogs/>} label={<p className='normal-case font-semibold text-dashboard-black'>Recycle Bin</p>} />
          </Tabs>
      </Box>
    </div>
  )
}

export default TopPart