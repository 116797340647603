import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import Items from '../../../../Components'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import { addElement } from '../../../../../../app/slices/blogSlice'
import TextComponent from './TextComponent'

const Text = ({text}) => {
    const [open, setopen] = useState(false)
    const [textp, settextp] = useState('')
    let blogs = useSelector(state=> state.blog)
    const dis = useDispatch()
    const [{ opacity }, dragRef] = useDrag(
        () => ({
          type: Items.TOOL,
          item: { text },
          end:(item,monitor)=>{
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
              setopen(true)
            }
          },
          collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1
          })
        }),
        []
      )
      const addText = ()=>{
        dis(addElement({id:Array.from(blogs).length+1,html:textp,code:703,compo:<TextComponent id={Array.from(blogs).length+1} text={textp}/>}))
        setopen(false)
        settextp('')
      }
  return (
    <button className='flex flex-col items-center' ref={dragRef} style={{ opacity}}>
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width="46"
  height="46"
  fill="none"
  viewBox="0 0 46 46"
>
  <circle cx="23" cy="23" r="23" fill="#FFF27C"></circle>
  <path
    stroke="#0D0D0D"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2.5"
    d="M13 16.75V13h20v3.75M19.25 33h7.5M23 13v20"
  ></path>
</svg>
<p className='text-center text-xs text-theme-orange uppercase mt-2'>Text</p>
<Dialog open={open} onClose={e=> setopen(false)}>
  <DialogTitle  className='bg-dashboard-black text-theme-orange'>
    Add Text
  </DialogTitle>
  <DialogContent className='bg-dashboard-dark-black'>
    <DialogContentText>
      <p className='text-gray-500 mt-3 mb-2 italic text-sm'>Write your text here and press the add button</p>
      <textarea type="text" className='ring-1 ring-theme-orange rounded-xl p-3 w-full bg-transparent text-white text-sm' value={textp} onChange={e=> settextp(e.target.value)} rows={10}>
      </textarea>
    </DialogContentText>
  </DialogContent>
  <DialogActions className='bg-dashboard-dark-black'>
    <ButtonGroup variant="contained" aria-label="" size="small">
      <Button color="success" onClick={addText}>Add</Button>
      <Button color="error" onClick={e=> setopen(false)}>Cancel</Button>
    </ButtonGroup>
  </DialogActions>
</Dialog>
</button>
  )
}

export default Text