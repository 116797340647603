import {  Delete, Edit, Unarchive } from '@mui/icons-material'
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import auth from '../../../../firebase'
import axios from 'axios'
import url from '../../../../apiurl'

const ArchiveItem = ({title,time,desc,feature,slug,author,getBlogs,id}) => {
    const [arch, setarch] = useState(false)
    const [del, setdel] = useState(false)
    const makeVisible = ()=>{
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.patch(`${url}/blogs/restore/${id}`,{},{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status===200){
            setdel(false)
            getBlogs()
          }
          else{
            alert('Error! Please check the console')
            console.log(res.data)
          }
        }).catch((err)=>{
          alert('Error! Please check the console')
          console.log(err)
        })
      })
    }
    const deleteBlog = ()=>{
    auth.currentUser.getIdToken(true).then((token)=>{
        axios.patch(`${url}/blogs/delete/${id}`,{},{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status===200){
            setdel(false)
            getBlogs()
          }
          else{
            alert('Error! Please check the console')
            console.log(res.data)
          }
        }).catch((err)=>{
          alert('Error! Please check the console')
          console.log(err)
        })
      })
    }
    return (
        <div className='rounded-lg bg-dashboard-dark-black p-3 w-full text-white my-2 grid md:grid-cols-10 grid-cols-1'>
          <img src={feature} alt={title} className='col-span-2 rounded-lg ring-1 ring-gray-700'/>
          <div className="col-span-6 px-5">
            <h1 className='text-xl text-white'>{title}</h1>
            <p className='text-gray-500 font-extralight text-sm mt-5'>{time}</p>
            <p className="mt-2 text-xs text-gray-500">{desc}</p>
          </div>
          <div className="col-span-2 flex items-center justify-center">
            <div className="px-3 rounded-full bg-[#202020] flex [&>*]:mx-2">
              <IconButton sx={{color:'#fff'}}>
                <Edit/>
              </IconButton>
              <IconButton sx={{color:'#fff'}} onClick={e=> setarch(true)}>
                <Unarchive/>
              </IconButton>
              <IconButton sx={{color:'#FF4747'}} onClick={e=> setdel(true)}>
                <Delete/>
              </IconButton>
            </div>
          </div>
          {/* Delete Dialog */}
          <Dialog open={del} aria-labelledby="Delete dialog">
            <DialogTitle className='bg-dashboard-black text-theme-orange'>
              Are you sure?
            </DialogTitle>
            <DialogContent className='bg-dashboard-dark-black'>
              <DialogContentText sx={{color:'#d7d7d7',py:3}}>
                Are you sure that you want to delete this blog?
              </DialogContentText>
            </DialogContent>
            <DialogActions className='bg-dashboard-dark-black'>
              <ButtonGroup variant="contained">
                <Button color="error" onClick={deleteBlog}>Delete</Button>
                <Button color="success" onClick={e=> setdel(false)}>Cancel</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
          {/* Archieve Dialog */}
          <Dialog open={arch} aria-labelledby="Archive Dialog">
            <DialogTitle className='bg-dashboard-black text-theme-orange'>
              Want to Unarchive?
            </DialogTitle>
            <DialogContent className='bg-dashboard-dark-black'>
              <DialogContentText sx={{color:'#d7d7d7',py:3}}>
                If you unarchive the blog will be visible to the user side. You can archive it anytime
              </DialogContentText>
            </DialogContent>
            <DialogActions className='bg-dashboard-dark-black'>
              <ButtonGroup variant="contained" aria-label="">
                <Button color="success" onClick={makeVisible}>Unrchieve</Button>
                <Button color="error" onClick={e=> setarch(false)}>Cancel</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
        </div>
      )
}

export default ArchiveItem