import React, { useEffect, useState } from 'react'
import AccessSwitch from './AccessSwitch/AccessSwitch'
import UserTop from './UserTop/UserTop'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DeleteForever, RemoveRedEye, Save, Visibility, VisibilityOff } from '@mui/icons-material'
import auth from '../../../firebase'
import axios from 'axios'
import url from '../../../apiurl'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import {setcomponent} from '../../../app/slices/dashboardSlice'
import AccessControl from '../AccessControl'
import { signInWithEmailAndPassword } from 'firebase/auth'


const SingleAccess = ({obj}) => {
  const [accarr, setaccarr] = useState([])
  const [del, setdel] = useState(false)

  const dis = useDispatch()
  //Access
  const [upblog, setupblog] = useState(false)
  const [mblog, setmblog] = useState(false)
  const [spread, setspread] = useState(false)
  const [gall, setgall] = useState(false)
  const [cer, setcer] = useState(false)
  const [bug, setbug] = useState(false)
  const [acc, setacc] = useState(false)
  const [passopen, setpassopen] = useState(false)
  const [pass, setpass] = useState('')
  const [passtype, setpasstype] = useState(false)

  const getAccess = ()=>{
    if(auth.currentUser!=null){
      auth.currentUser.getIdToken(true).then((token)=>{
          axios.get(`${url}/user/access/${obj.uid}`,{
            headers:{
              'Authorization':token
            }
          }).then((res)=>{
            if(res.status===200){
              const arr = Array.from(res.data.result).map(obj=> obj.access)
              setaccarr(arr)
              if(arr.includes(200)){
                setupblog(true)
              }
              if(arr.includes(201)){
                setmblog(true)
              }
              if(arr.includes(204)){
                setspread(true)
              }
              if(arr.includes(205)){
                setgall(true)
              }
              if(arr.includes(206)){
                setcer(true)
              }
              if(arr.includes(207)){
                setbug(true)
              }
              if(arr.includes(230)){
                setacc(true)
              }
            }
            else{
              alert('Permission Denied')
            }
          }).catch((err)=>{
            console.log(err)
          })
      }).catch((err)=>{
        console.log(err)
      })
    }
    else{
      alert('User not logged in')
    }
  }

  useEffect(() => {
    getAccess()
  }, [])
  
  useEffect(() => {
    
  }, [])
  
  const deleteAcc = ()=>{
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.patch(`${url}/user/delete`,{
          uid:obj.uid
        },
        {
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status===200){
            setdel(false)
            dis(setcomponent({title:'Access Control',component:<AccessControl/>}))
          }
          else{
            
          }
        }).catch((err)=>{
          console.log(err)
        })
      }).catch((err)=>{
        console.log(err)
      })
  }

  const verifyPassword = ()=>{
      signInWithEmailAndPassword(auth,auth.currentUser.email,pass).then((user)=>{
        console.log(user)
        setpassopen(false)
        setpass('')
        setdel(true)
      }).catch((err)=>{
        let errorMessage;
        switch (err.code) {
          case "auth/invalid-email":
            errorMessage = "Invalid email address.";
            break;
          case "auth/user-disabled":
            errorMessage = "User account is disabled.";
            break;
          case "auth/user-not-found":
            errorMessage = "User not found.";
            break;
          case "auth/wrong-password":
            errorMessage = "Invalid password.";
            break;
          default:
            errorMessage = "An error occurred. Please try again.";
            break;
        }
        alert(errorMessage)
      })
  }
  return (
    <div className=''>
        <UserTop obj={obj}/>
        <div className="grid grid-cols-1 md:grid-cols-3 [&>*]:m-2 mt-10">
         
        <AccessSwitch text="Access Control Ability"  val={acc} setval={setacc}/>
        <AccessSwitch text="Can Upload Blogs"  val={upblog} setval={setupblog}/>
        <AccessSwitch text="Can Manage Blogs"  val={mblog} setval={setmblog}/>
        <AccessSwitch text="Access To Spreadsheets" val={spread} setval={setspread} />
        <AccessSwitch text="Can Manage Gallary" val={gall} setval={setgall} />
        <AccessSwitch text="Can Generate Certificates" val={cer} setval={setcer} />
        <AccessSwitch text="Can Send Bug Reports"  val={bug} setval={setbug}/>
          

        </div>
        <center>
          <ButtonGroup variant="contained" sx={{my:10}} aria-label="">
            <Button startIcon={<DeleteForever/>} color="error" onClick={e=> setpassopen(true)}>Delete User</Button>
            <Button startIcon={<Save/>} color="primary">Save Data</Button>
            
          </ButtonGroup>
        </center>
        <Dialog open={del} onClose={e=> setdel(false)}>
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Are You Sure?
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <p className="my-3 text-sm text-gray-400">The user will not be able to login again using this account</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
           <ButtonGroup variant="contained" aria-label="">
             <Button variant="contained" color="error" onClick={deleteAcc}>Delete</Button>
             <Button variant="contained" color="success" onClick={e=> setdel(false)}>Cancel</Button>
           </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog open={passopen}>
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Verify Your Password
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <p className="text-white text-sm mt-4">Verify your password first to delete an account</p>
            <input type={!passtype?'password':'text'} value={pass} onChange={e=> setpass(e.target.value)} className='ring-1 ring-theme-orange p-3 rounded-md text-theme-orange w-full my-2 bg-transparent' />
            <Button variant="text" color="warning" onClick={e=> setpasstype(p=> !p)}>
              {passtype?<><VisibilityOff sx={{mx:1}}/> Hide Password</>:<><Visibility sx={{mx:1}}/> Show Password</>}
            </Button>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
            <ButtonGroup variant="contained" aria-label="" size="small">
              <Button color="success" onClick={verifyPassword}>Verify</Button>
              <Button color="error" onClick={e=> setpassopen(false)}>Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default SingleAccess