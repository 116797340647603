import React from 'react'

const UserTop = ({obj}) => {
  return (
    <div className='grid md:grid-cols-10 grid-cols-2'>
        <div className="col-span-2">
          <div className="h-48 w-48 overflow-hidden p-1">
            <img src={obj&&obj.photo} className='h-full w-full object-cover object-center rounded-full ring-2 ring-gray-600' alt="Profile"/>
          </div>
            
        </div>
        <div className="col-span-8 flex flex-col justify-center">
            <h1 className='text-white text-3xl font-semibold'>{obj&&obj.userName}</h1>
            <p className='mt-5 text-gray-400 font-light text-sm'>{obj&&obj.email}</p>
            <p className='mt-2 text-gray-400 text-sm font-light'>{obj&&obj.phone}</p>
        </div>
    </div>
  )
}

export default UserTop