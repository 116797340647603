import React from "react";

function SpreadSheet({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
    <path
      fill={selected?"#313131":"#FFF27C"} className="transition-all duration-300 ease-in-out"
      d="M31.75 20.75v-2.5h-10v-5h-2.5v5h-5v2.5h5v10h2.5v-10h10zm0-10c.625 0 1.25.25 1.737.762.513.488.763 1.113.763 1.738v17.5c0 .625-.25 1.25-.763 1.737a2.384 2.384 0 01-1.737.763h-17.5c-.625 0-1.25-.25-1.738-.763a2.384 2.384 0 01-.762-1.737v-17.5c0-.625.25-1.25.762-1.738a2.384 2.384 0 011.738-.762h17.5z"
    ></path>
  </svg>
  );
}

export default SpreadSheet;
