import { createSlice } from "@reduxjs/toolkit";
import ProfilePage from "../../Components/ProfilePage/ProfilePage";

export const dashboard = createSlice({
    name:'dashboard',
    initialState:{
        title:'Profile',
        component:<ProfilePage/>
    },
    reducers:{
        settitle: (state,action)=>{
            state.title = action.payload
        },
        setcomponent:(state,action)=>{
            state.component = action.payload
        },
        setdashboard: (state,action)=>{
            state.title = action.payload.title
            state.component = action.payload.component
        }
    }
})
export default dashboard.reducer
export const {settitle,setcomponent,setdashboard} = dashboard.actions