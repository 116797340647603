import React from 'react'
import AccessBox from '../Menu/ProfileBox/AccessBox/AccessBox'
import ChangePhoto from './ChangePhoto/ChangePhoto'
import EmailBox from './Emailbox/EmailBox'
import { Logout } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import fire from '../../firebase'

const ProfilePage = () => {
  const user = useSelector(state=> state.user)
  const access = useSelector(state=> state.access)
  const logOut = ()=>{
    fire.signOut().then(()=>{
      if(typeof window!== "undefined"){
        window.location.reload()
      }
      else{
        alert('Please refresh the page')
      }
    }).catch((err)=>{
      console.log(err)
      alert('Failed! Check Console')
    })
  }
  return (
    <div>
        <div className="grid grid-cols-10 items-center">
            <div className="col-span-2">
              <div className="h-52 w-52 overflow-hidden p-1">
                <img src={user&& user.photo} className='rounded-full ring-2 ring-theme-orange object-cover object-center h-full w-full' alt="Profile"/>
              </div>
                
            </div>
            <div className="col-span-8 mx-4">
                <h1 className='text-white text-3xl font-semibold uppercase'>{user && user.userName}</h1>
                <p className="mt-5 text-gray-500">{user&&user.position}</p>
                <p className="text-white text-sm mt-2 uppercase">Admin ID: {user&&user.uid}</p>
            </div>
            <div className="col-span-10">
            <AccessBox arr={access.codes}/>
            </div> 
            <div className="col-span-4">
              <ChangePhoto/>
            </div>
            <div className="col-span-6">
              <EmailBox email={user&&user.email} phone={user&& user.phone}/>
            </div>
            <div className="col-span-4 px-3 py-5">
              <button className='bg-theme-orange px-5 rounded-xl py-3 transition-all ease-in-out duration-300 hover:bg-rose-700 font-semibold hover:text-white' onClick={logOut}>
                <Logout/> Logout
              </button>
            </div>
        </div>
    </div>
  )
}

export default ProfilePage