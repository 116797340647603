import React, { useState } from 'react'
import TopPart from './TopPart/TopPart'
import BlogContainer from './BlogContainer/BlogContainer'




const BlogPage = () => {
  const [compo, setcompo] = useState(<BlogContainer/>)

  return (
    <div>
        <TopPart setcompo={setcompo} compo={compo}/>
        {compo}
    </div>
  )
}

export default BlogPage