import React from "react";

function AdminSVG({w,h}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 211 25"
    >
      <path
        fill="#fff"
        d="M0 25h4.767l7.92-14.264-2.364-4.317L0 25zM16.254 4.28L13.85 0l-2.365 4.28 2.365 4.279L22.973 25h4.73L16.253 4.28zM52.815 10.848C51.95 4.805 46.809.15 40.578.075H30.48V3.68h10.323c1.877.112 3.603.75 4.992 1.801a8.996 8.996 0 013.379 5.368c.112.563.187 1.164.187 1.765 0 .6-.075 1.238-.225 1.839-.75 3.604-3.716 6.419-7.432 6.982h-7.62V5.48H30.48V25h11.486c5.593-.676 10.023-5.03 10.849-10.548.075-.6.112-1.239.112-1.84 0-.6-.037-1.2-.112-1.764zM56.544 25h3.603V11.224L56.544 7.62V25zm11.223-13.814L56.544 0v5.068L67.767 16.29l1.352-1.389 1.163-1.163 4.918-4.88V25h3.603V.15L67.767 11.186zM82.387 25V.038h3.604V25h-3.604zM85.878.15H82.5v24.737h3.378V.15zm-3.49-.112V25h3.603V.038h-3.604zM89.572 25h3.604V11.261l-3.604-3.603V25zM105.788.075v16.141L89.572.038v5.067l19.782 19.782V.075h-3.566zM144.248 10.736H136.14a3.545 3.545 0 01-3.191-3.529 3.525 3.525 0 013.529-3.528h7.92a3.545 3.545 0 013.416 3.34h3.604a7.18 7.18 0 00-.939-3.34h-.037a7.125 7.125 0 00-6.006-3.604h-7.958c-2.628 0-4.955 1.464-6.194 3.604a7.086 7.086 0 00-.938 3.528c0 1.276.338 2.515.938 3.529a6.85 6.85 0 001.089 1.426 7.025 7.025 0 005.105 2.177h7.77c1.952 0 3.566 1.577 3.566 3.529s-1.614 3.566-3.566 3.566h-7.732c-1.915 0-3.454-1.502-3.567-3.378h-3.603a7.33 7.33 0 00.938 3.378A7.177 7.177 0 00136.516 25h7.732a7.175 7.175 0 006.231-3.566c.564-1.051.939-2.252.939-3.566 0-1.276-.375-2.478-.939-3.529a7.209 7.209 0 00-6.231-3.603zM154.97 25V.038h3.603V25h-3.603zM158.461.15h-3.379v24.737h3.379V.15zM154.97.038V25h3.603V.038h-3.603zM174.88 25V5.48h-3.604V25h3.604zm9.534-21.321V.075h-22.259V3.68h22.259zM192.015 13.438h16.028V9.835h-16.028V5.48h-3.604V25h22.072v-3.566h-18.468v-7.996zM188.411.075V3.68h22.072V.075h-22.072z"
      ></path>
    </svg>
  );
}

export default AdminSVG;
