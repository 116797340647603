import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name:'user',
    initialState:{
            userName:'',
            uid:'',
            photo:'',
            position:'',
            email:'',
            phone:''
    },
    reducers:{
        setuser:(state,action)=>{
            state = action.payload
        },
        setusername: (state,payload)=>{
         state.userName = payload.payload   
        },
        setuid : (state,payload)=>{
            state.uid = payload.payload
        },
        setphoto: (state,action)=>{
            state.photo = action.payload
        },
        setemail: (state,action)=>{
            state.email = action.payload
        },
        setphone: (state,action)=>{
            state.phone = action.payload
        },
        setposition: (state,action)=>{
            state.position = action.payload
        }
    }
})
export default userSlice.reducer
export const {setuser,setusername,setuid,setphoto,setphone,setemail,setposition} = userSlice.actions