import { Add, Delete } from '@mui/icons-material'
import { Button, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import axios from 'axios'
import toast,{ Toaster } from 'react-hot-toast'
import React, { useEffect, useState } from 'react'
import auth from '../../../../firebase'
import apiurl from '../../.../../../../apiurl'
const CreateCareer = () => {
    const [jobtitle, setjobtitle] = useState('')
    const [position, setposition] = useState('')
    const [salary, setsalary] = useState('')
    const [jobLocation, setjobLocation] = useState('')
    const [details, setdetails] = useState('');
    const [fields, setFields] = useState([''])
    const [err, seterr] = useState(false)
    const [errtext, seterrtext] = useState('')


    function handleChange(i, event) {
        const values = [...fields];
        values[i] = event.target.value;
        setFields(values);
      }
    
      function handleAdd() {
        const values = [...fields];
        values.push("");
        setFields(values);
      }
    
     function handleRemove(i) {
       const values = [...fields];
       values.splice(i, 1);
       setFields(values);
     }


     

    const postJob = ()=>{
        if(!position){
            seterrtext('Add Position')
            seterr(true)
        }
        else if(!jobtitle){
            seterrtext('Add Position for the job')
            seterr(true)
        }
        else if(!jobLocation){
            seterrtext('Add Job Location for the job')
            seterr(true)
        }
        else if(!salary){
            seterrtext('Add Salary for the job')
            seterr(true)
        }
        else if(!details){
            seterrtext('Add Details for the job')
            seterr(true)
        }
        else{
            auth.currentUser.getIdToken(true).then((token)=>{
                axios.post(`${apiurl}/career`,{
                    uid: auth.currentUser.uid,
                    location:jobLocation,
                    title:jobtitle,
                    desc:details,
                    salary,
                    position,
                    jid:new Date().getTime(),
                    published: `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`,
                    time: new Date().toLocaleTimeString(),
                    fields
                },{
                    headers:{
                        'Authorization':token
                    }
                }).then((res)=>{
                    if(res.status == 200){
                        console.log(res.data)
                        setjobLocation('')
                        setjobtitle('')
                        setsalary('')
                        setdetails('')
                        setposition('')
                        setFields([''])
                        toast.success('Job Posted!',{
                            duration:2000
                        })
                    }
                    else{
                        console.log(res.data)
                        toast.error('An error occured!')
                    }
                }).catch((err)=>{
                    console.log(err)
                    toast.error('An error occured!')
                })
            }).catch((err)=>{
                console.log(err)
                toast.error('An error occured!')
            })
        }
        
    }

  return (
    <div className='grid grid-cols-2'>
        <div className="col-span-2 text-center text-theme-orange text-2xl font-semibold mb-5">Basic Information</div>
        <div className="p-3">
            <label className='text-theme-orange text-sm'>Job Title</label> <br />
            <input value={jobtitle} onChange={e=> setjobtitle(e.target.value)} className='p-3 bg-transparent w-full text-theme-orange rounded-md mt-2 border border-theme-orange'/>
        </div>
        <div className="p-3">
            <label className='text-theme-orange text-sm'>Position</label> <br />
            <input value={position} onChange={e=> setposition(e.target.value)} className='p-3 bg-transparent w-full text-theme-orange rounded-md mt-2 border border-theme-orange'/>
        </div>
        <div className="p-3">
            <label className='text-theme-orange text-sm'>Salary</label> <br />
            <input value={salary} onChange={e=> setsalary(e.target.value)} className='p-3 bg-transparent w-full text-theme-orange rounded-md mt-2 border border-theme-orange'/>
        </div>
        <div className="p-3">
            <label className='text-theme-orange text-sm'>Job Location</label> <br />
            <input value={jobLocation} onChange={e=> setjobLocation(e.target.value)} className='p-3 bg-transparent w-full text-theme-orange rounded-md mt-2 border border-theme-orange'/>
        </div>
        <div className="col-span-2 p-3">
            <label className='text-theme-orange text-sm'>Details and Requirements</label> <br />
            <textarea value={details} onChange={e=> setdetails(e.target.value)} rows={6} className='w-full border border-theme-orange p-2 text-theme-orange bg-transparent rounded-md mt-3'/>
        </div>
        
        <div className="">
         <label className='text-theme-orange text-2xl text-center font-bold'>Custom Fields</label> <br />
         <List>
            {fields.map((v,i)=> <ListItem key={i}>
                <ListItemText>
                    <input className='border border-theme-orange rounded-md w-full bg-transparent p-3  text-sm text-theme-orange' value={v} onChange={e=> handleChange(i,e)} />
                </ListItemText>
                   <button className='bg-theme-orange rounded-md transition-all ease-in-out duration-200 ml-3 h-full p-3 hover:bg-theme-orange/80' onClick={e=> handleRemove(i)}>
                    <Delete/>
                   </button>
            </ListItem>)}
            
         </List>
         
         <button className='bg-theme-orange rounded-md transition-all ease-in-out duration-200 hover:bg-theme-orange/80 px-5 py-2 mx-3 font-semibold' onClick={handleAdd}>Add Item <Add/> </button>
         
        </div>
        <div className="col-span-2 flex justify-center">
            <button className='bg-theme-orange hover:bg-theme-orange/80 px-5 py-2 transition-all ease-in-out duration-300 font-semibold rounded-lg' onClick={postJob}>Publish</button>
        </div>
        <Dialog open={err} onClose={e=> seterr(false)}>
          <DialogTitle style={{backgroundColor:'#222',color:"#efefef"}}>
            Please Check
          </DialogTitle>
          <DialogContent style={{backgroundColor:'#222',color:'#fff'}}>
            <DialogContentText style={{color:'#fff'}}>
              {errtext}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{background:'#333'}}>
            <Button onClick={e=> seterr(false)} color="error">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Toaster/>
    </div>
  )
}

export default CreateCareer