import React from 'react'
import LinkItem from './LinkItem/LinkItem'

const MoreLinks = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2'>
        <LinkItem img="/namecheap.png" title="Namecheap" subOne="adsecbd.com" subTwo="Domain and hosting provider" link="https://namecheap.com/" />
        <LinkItem img="/epson.png" title="Epson Connect™" subOne="Epson" subTwo="Printer Driver" link="https://ftp.epson.com/drivers/ECPSU_143.exe" />
        <LinkItem img="/gmail.png" title="GMail" subOne="Google" subTwo="Mail Service" link="https://mail.google.com/mail/u/0/" />
        <LinkItem img="/enconiya.png" title="Enconiya" subOne="IT Company" subTwo="Development & Design Solution" link="https://enconiya.agency/" />
        <LinkItem img="/hp.png" title="HP Smart" subOne="HP" subTwo="Printer Driver" link="https://apps.microsoft.com/store/detail/hp-smart/9WZDNCRFHWLH?hl=en-us&gl=us" />
        <LinkItem img="/tplink.png" title="Tp Link" subOne="Tplink Wi-Fi" subTwo="router setup" link="http://tplinkwifi.net/webpages/login.html" />
        <LinkItem img="/map.png" title="Adsec Map" subOne="Google Map" subTwo="Adsec Location" link="https://www.google.com/maps/place/ADSEC+Education+Bangladesh/@23.7494209,90.3698566,17z/data=!3m1!4b1!4m6!3m5!1s0x3755bf4fa75e08d7:0xb588098d9ca8e503!8m2!3d23.7494209!4d90.3720453!16s%2Fg%2F11kb4vltny?coh=164777&entry=tt&shorturl=1" />
        <LinkItem img="/office.png" title="Office 365" subTwo="Word, Excel, Power-point & more" link="https://drive.google.com/uc?export=download&id=1PPpb4CW-ok7xjUp1hfpxljTnqjvSn3bg&confirm=t&uuid=35a28ae1-fa83-4398-a760-d6e2c692d441&at=ANzk5s6TZ0H0K-iKe-FqOZ5M19QB:1680202562371" />
        <LinkItem img="/testing.png" title="BDIX Tester" subOne="TV & FTP" subTwo="Server Scanner" link="https://gamitisa.com/tools/bdix-tester/" />
        <LinkItem img="/icc.png" title="ICC FTP" subOne="10.16.100.244" link="http://10.16.100.244/" />
        <LinkItem img="/circle.png" title="Circle FTP" subOne="103.170.204.84" link="http://103.170.204.84/" />
        <LinkItem img="/abox.png" title="ABOX FTP" subOne="103.133.143.18" link="http://103.133.143.18/" />
    </div>
  )
}

export default MoreLinks