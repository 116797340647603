import { Delete, Edit } from '@mui/icons-material'
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editElement, removeElement } from '../../../../../../app/slices/blogSlice'

const TextComponent = ({id,text}) => {
    let blogs = useSelector(state=> state.blog)
    const dis = useDispatch()
    const [del, setdel] = useState(false)
    const [edit, setedit] = useState(false)
    const [textp, settextp] = useState(text)
    const removeElem = ()=>{
        const index = Array.from(blogs).map(v=> v.id).indexOf(id)
        dis(removeElement(index))
        setdel(false)
    }
    const saveChange = ()=>{
        const index  = Array.from(blogs).map(v=> v.id).indexOf(id)
        dis(editElement({index,obj:{id,html:textp,code:703,compo:<TextComponent id={id} text={textp}/>}}))
        setedit(false)
    }
  return (
    <div className='p-3 m-2 rounded-xl bg-dashboard-black'>
        <p className='text-sm text-white' dangerouslySetInnerHTML={{__html:String(text).replace(/\n/g,'<br/>')}}></p>
<div className="flex w-full justify-end">
          <IconButton onClick={e=> setedit(true)}>
            <Edit sx={{color:'#FFF27C'}}/>
          </IconButton>
          <IconButton onClick={e=> setdel(true)}>
            <Delete sx={{color:'#FFF27C'}}/>
          </IconButton>
        </div>
<Dialog open={del} onClose={e=> setdel(false)}>
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Want to Remove?
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <p className='my-2 text-sm text-white'>Want to remove this image?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
           <ButtonGroup variant="contained" aria-label="" size="small">
             <Button onClick={removeElem} color="error">Remove</Button>
             <Button onClick={e=> setdel(false)} color="success">Cancel</Button>
           </ButtonGroup>
          </DialogActions>
        </Dialog>
        <Dialog open={edit} aria-labelledby="" fullWidth>
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Edit <Edit/>
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <textarea className='ring-1 ring-theme-orange rounded-xl mt-3 p-3 bg-transparent w-full text-white' value={textp} onChange={e=> settextp(e.target.value)} rows={10}></textarea>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
          <ButtonGroup variant="contained" size="small" aria-label="">
            <Button color="success" onClick={saveChange}>Save</Button>
            <Button color="error" onClick={e=> setedit(false)}>Cancel</Button>
          </ButtonGroup>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default TextComponent