import React from "react";

function Images({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
    <path
      fill={selected?"#313131":"#FFF27C"} className="transition-all duration-300 ease-in-out"
      d="M27.688 18.719a1.406 1.406 0 01-2.403.996 1.394 1.394 0 01-.41-.996 1.406 1.406 0 012.813 0zm7.5-5.157v16.876a1.875 1.875 0 01-1.876 1.875H12.689a1.875 1.875 0 01-1.876-1.876V13.563a1.875 1.875 0 011.876-1.874h20.624a1.875 1.875 0 011.876 1.874zm-1.876 12.739V13.563H12.689v10.863l4.3-4.301a1.887 1.887 0 012.649 0l5.238 5.238 2.426-2.425a1.887 1.887 0 012.648 0l3.363 3.363z"
      ></path>
  </svg>
  );
}

export default Images;
