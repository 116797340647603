import { LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import SiteIcon from '../../icons/SiteIcon'

const Preloader = ({active}) => {
  return (
    <>
    {active? <div className={`fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center h-full w-full bg-black z-50`}>
        
        <SiteIcon h={100} mode="dark"/>
        <Box sx={{width:'300px',mt:3}}>
        <LinearProgress/>
        </Box>
    </div>: null}
    </>
  )
}

export default Preloader