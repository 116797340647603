import { Delete, Edit } from '@mui/icons-material'
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ButtonGroup } from '@mui/material'
import React, { useState } from 'react'
import './Button.css'
import ArrowIcon from './ArrowIcon'
import { useDispatch, useSelector } from 'react-redux'
import { editElement, removeElement } from '../../../../../../app/slices/blogSlice'
const ButtonCompo = ({id,text,link}) => {
    const [del, setdel] = useState(false)
    const [edit, setedit] = useState(false)
    const [edlink, setedlink] = useState(link)
    const [edtext, setedtext] = useState(text)
    let blogs = useSelector(state=> state.blog)
    const dis = useDispatch()
    const removeElem = ()=>{
            const index = Array.from(blogs).map(v=> v.id).indexOf(id)
            dis(removeElement(index))
            setdel(false)
    }
    const editElem = ()=>{
        const index  = Array.from(blogs).map(v=> v.id).indexOf(id)
        dis(editElement({index,obj:{id,html:`${edtext}%link:%${edlink}`,code:701,compo:<ButtonCompo id={id} text={edtext} link={edlink}/>}}))
        setedit(false)
    }
  return (
    <div className='p-3 m-2 rounded-xl bg-dashboard-black'>
        <a href={link} target="_blank" rel="noreferrer">
        <button className='btnCarousel'>{text}<ArrowIcon w={20} h={20}/></button>
        </a>
        <div className="flex w-full justify-end">
          <IconButton onClick={e=> setedit(true)}>
            <Edit sx={{color:'#FFF27C'}}/>
          </IconButton>
          <IconButton onClick={e=> setdel(true)}>
            <Delete sx={{color:'#FFF27C'}}/>
          </IconButton>
        </div>
        <Dialog open={del} onClose={e=> setdel(false)} aria-labelledby="Delete dialog">
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Want To Remove?
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <p className='my-3 text-white text-sm'>Press the delete button to remove</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
            <ButtonGroup variant="contained" size="small" aria-label="">
              <Button color="error" onClick={removeElem}>Delete</Button>
              <Button color="success" onClick={e=> setdel(false)}>Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog open={edit} fullWidth aria-labelledby="Edit Dialog">
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Edit Button <Edit/>
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <div className="pt-5">
                <label htmlFor="#edtext" className='text-theme-orange text-sm'>Edit Link</label>
                <input type="text" id="edtext" className='w-full mt-1 bg-transparent ring-1 ring-theme-orange rounded-xl p-3 text-white' value={edlink} onChange={(e)=> setedlink(e.target.value)}/>
                <div className="mt-3">
                <label htmlFor="#edtext" className='text-theme-orange text-sm mt-5'>Edit Text</label>
                <input type="text" id="edtext" className='w-full mt-1 bg-transparent ring-1 ring-theme-orange rounded-xl p-3 text-white' value={edtext} onChange={(e)=> setedtext(e.target.value)}/>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
            <ButtonGroup variant="contained" size="small" aria-label="">
              <Button color="success" onClick={editElem}>Save</Button>
              <Button color="error" onClick={e=> setedit(false)}>Cancel</Button>
              
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default ButtonCompo