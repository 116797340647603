import React from 'react'
import AdminSVG from './SVG/AdminSVG'

const TopMenu = () => {
  return (
    <div className='bg-[#0D0D0D] w-full -mt-2 pt-5 pl-3 mb-5 text-white grid grid-cols-5'> 
        <div className="col-span-5 flex items-center justify-center">
            <AdminSVG w={120} h={30}/>
        </div>
        {/* <div className=" flex items-center justify-center">
            <Logos h={30} w={40}/>
        </div> */}
    </div>
  )
}

export default TopMenu