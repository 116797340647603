import React from "react";

function AdminPanel({w,h}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 310 37"
    >
      <path
        fill="#fff"
        d="M.792 36.665h6.985l11.605-20.9-3.465-6.325L.792 36.665zm23.815-30.36l-3.52-6.27-3.465 6.27 3.465 6.27 13.365 24.09h6.93L24.607 6.305zM78.176 15.93C76.911 7.075 69.376.255 60.246.145H45.451v5.28h15.125c2.75.165 5.28 1.1 7.315 2.64a13.18 13.18 0 014.95 7.865c.165.825.275 1.705.275 2.585 0 .88-.11 1.815-.33 2.695-1.1 5.28-5.445 9.405-10.89 10.23H50.731V8.065h-5.28v28.6h16.83c8.195-.99 14.685-7.37 15.895-15.455.11-.88.165-1.815.165-2.695 0-.88-.055-1.76-.165-2.585zM83.64 36.665h5.28V16.48l-5.28-5.28v25.465zm16.445-20.24L83.64.035V7.46l16.445 16.445 1.98-2.035 1.705-1.705 7.205-7.15v23.65h5.28V.255l-16.17 16.17zM121.506 36.665V.09h5.28v36.575h-5.28zm5.115-36.41h-4.95V36.5h4.95V.255zM121.506.09v36.575h5.28V.09h-5.28zM132.033 36.665h5.28v-20.13l-5.28-5.28v25.41zm23.76-36.52v23.65L132.033.09v7.425L161.018 36.5V.145h-5.225zM212.145 15.765h-11.88a5.194 5.194 0 01-4.675-5.17c0-2.86 2.31-5.17 5.17-5.17h11.605c2.695.11 4.84 2.2 5.005 4.895h5.28a10.528 10.528 0 00-1.375-4.895h-.055a10.44 10.44 0 00-8.8-5.28h-11.66c-3.85 0-7.26 2.145-9.075 5.28-.88 1.54-1.375 3.3-1.375 5.17s.495 3.685 1.375 5.17c.44.77.99 1.485 1.595 2.09 1.87 1.98 4.565 3.19 7.48 3.19h11.385c2.86 0 5.225 2.31 5.225 5.17s-2.365 5.225-5.225 5.225h-11.33c-2.805 0-5.06-2.2-5.225-4.95h-5.28a10.75 10.75 0 001.375 4.95 10.515 10.515 0 009.13 5.225h11.33c3.905 0 7.315-2.09 9.13-5.225.825-1.54 1.375-3.3 1.375-5.225 0-1.87-.55-3.63-1.375-5.17-1.815-3.135-5.225-5.28-9.13-5.28zM227.854 36.665V.09h5.28v36.575h-5.28zm5.115-36.41h-4.95V36.5h4.95V.255zM227.854.09v36.575h5.28V.09h-5.28zM257.026 36.665v-28.6h-5.28v28.6h5.28zm13.97-31.24V.145h-32.615v5.28h32.615zM282.132 19.725h23.485v-5.28h-23.485v-6.38h-5.28v28.6h32.34V31.44h-27.06V19.725zm-5.28-19.58v5.28h32.34V.145h-32.34z"
      ></path>
    </svg>
  );
}

export default AdminPanel;
