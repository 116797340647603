import React from "react";

function KeyIcon({h,w}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#FFF27C"
        d="M8.75 18.75c1.042 0 1.927-.365 2.656-1.094.73-.729 1.094-1.614 1.094-2.656s-.365-1.927-1.094-2.656c-.729-.73-1.614-1.094-2.656-1.094s-1.927.365-2.656 1.094C5.364 13.073 5 13.958 5 15s.365 1.927 1.094 2.656c.729.73 1.614 1.094 2.656 1.094zm0 3.75c-2.083 0-3.854-.73-5.313-2.188C1.98 18.855 1.25 17.084 1.25 15s.73-3.854 2.188-5.313C4.896 8.23 6.667 7.5 8.75 7.5c1.688 0 3.162.48 4.422 1.438 1.26.958 2.14 2.145 2.64 3.562h10.47l2.468 2.469-4.375 5L21.25 17.5l-2.5 2.5-2.5-2.5h-.438a7.262 7.262 0 01-2.718 3.625c-1.292.917-2.74 1.375-4.344 1.375z"
      ></path>
    </svg>
  );
}

export default KeyIcon;
