import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCQjC_wNsCks10c382uj-92NQVGbwKJnmg",
  authDomain: "adecbangladesh.firebaseapp.com",
  databaseURL: "https://adecbangladesh-default-rtdb.firebaseio.com",
  projectId: "adecbangladesh",
  storageBucket: "adecbangladesh.appspot.com",
  messagingSenderId: "403499533591",
  appId: "1:403499533591:web:b8ce6eb58e72a30006a352",
  measurementId: "G-PW0JYLRTLM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
export default auth