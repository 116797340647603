
import Switch from '@mui/material/Switch'

const AccessSwitch = ({text,val,setval}) => {

  return (
    <div className='p-5 bg-dashboard-dark-black rounded-2xl grid grid-cols-10 items-center'>
      <p className='col-span-8 text-white text-sm'>{text}</p>
      <div className="col-span-2">
        <Switch
          checked={val}
          onChange={e=> setval(e.target.checked)}
          color='primary'
        />
      </div>
    </div>
  )
}

export default AccessSwitch