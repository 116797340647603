import React, { useState } from 'react'
import CareerTab from './CareerTab/CareerTab'
import CreateCareer from './CareerDashboard/CareerCreate/CreateCareer'

const CareerComponent = () => {
  const [compo, setcompo] = useState(<CreateCareer/>)
  return (
    <div>
      <CareerTab compo={compo} setcompo={setcompo}/>
      {compo}
    </div>
  )
}

export default CareerComponent