import React, { useState } from 'react'
import ProfileIcon from '../../../icons/ProfileIcon'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Upload } from '@mui/icons-material'
import { Box, LinearProgress } from '@mui/material'
import axios from 'axios'
import auth from '../../../firebase'
import apiurl from '../../../apiurl'
import { useDispatch } from 'react-redux'
import { setphoto } from '../../../app/slices/userSlice'

const ChangePhoto = () => {
  const [open, setopen] = useState(false)
  const [dpfile, setdpfile] = useState('')
  const [uploading, setuploading] = useState(false)
  const dis = useDispatch()
  const uploadDp = () =>{
    let file = new FormData()
        file.append('material',dpfile)
        setuploading(true)
        axios.post(`https://api.adsecbd.com/file`,file).then((urlres)=>{
          if(urlres.status===200){
            auth.currentUser.getIdToken(true).then((token)=>{
               axios.patch(`${apiurl}/user/photo/${auth.currentUser.uid}`,{
                photo: urlres.data.url
               },{
                headers:{
                  'Authorization':token
                }
               }).then(()=>{
                setdpfile('')
                dis(setphoto(urlres.data.url))
                setuploading(false)
                setopen(false)
               }).catch((err)=>{
                alert('Please try again')
               })
            }).catch((err)=>{
              alert('Maybe you are logged out')
            })
          }
        }).catch((err)=> console.log(err))
  }
  return (
    <>
    <div className='flex items-center justify-center rounded-lg bg-theme-black mx-3 h-16 cursor-pointer' onClick={e=> setopen(true)}>
        <ProfileIcon w={25}/>
        <p className='mx-2 text-white font-medium'>Change Profile Picture</p>
    </div>
    <Dialog open={open} onClose={e=> setopen(false)}>
      <DialogTitle className='bg-black'>
        <p className='text-theme-orange'>Change Profile Photo</p>
      </DialogTitle>
      <DialogContent className='bg-gray-800'>
        <DialogContentText>
          <center>
            <div className="mt-10 mb-5">
            <label className='px-5 py-3 bg-theme-orange text-black rounded-full font-semibold'>{dpfile?'Image Selected':'Select Image'} <input type="file" hidden onChange={e=> setdpfile(e.target.files[0])} /></label>
            </div>
            <p className="text-white text-xs">File Selected: {dpfile&& dpfile.name}</p>
            
          </center>
          {uploading&&
          <Box sx={{ width: '100%',mt:3}}>
            <LinearProgress />
          </Box>}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='bg-gray-900'>
        <ButtonGroup variant="contained" size='small' aria-label="">
          <Button color="success" onClick={uploadDp}><Upload/> Upload</Button>
          <Button color="error" onClick={e=> setopen(false)}>Close</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default ChangePhoto