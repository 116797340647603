import {createSlice} from '@reduxjs/toolkit'

export const blogSlice = createSlice({
    name:'upblog',
    initialState:[],
    reducers:{
        addElement:(state,action)=>{
            state.push(action.payload)
        },
        removeElement: (state,action)=>{
            state.splice(action.payload,1)
            state.map((v,i)=>{
                if(i>=action.payload){
                    state[i].id = (state[i].id)-1
                }
            })
        },
        editElement:(state,action)=>{
            state[action.payload.index] = action.payload.obj
      
        },
        clearblogs:(state,action)=>{
            state.splice(0,state.length)
        }
    }
})


export default blogSlice.reducer
export const {addElement,removeElement,editElement,clearblogs } = blogSlice.actions 