import React, { useEffect, useState } from 'react'
import CareerContainer from './CareerContainer/CareerContainer'
import axios from 'axios'
import apiurl from '../../../apiurl'
import auth from '../../../firebase'
const CareerResponse = () => {
  const [datas, setdatas] = useState([])
  const [keys, setkeys] = useState([])
  useEffect(() => {
    setkeys([''])
    setdatas([''])
    auth.currentUser.getIdToken(true).then((token)=>{
      axios.get(`${apiurl}/career`,{
        headers:{
          'Authorization':token
        }
      }).then((res)=>{
        setdatas(res.data)
        if(Array.isArray(res.data)){
          res.data.filter((v,i)=> !keys.includes(v.resid)&&setkeys(p=> typeof p==="object"&&[...p,v.resid]))
        }
      }).catch((err)=>{
        console.log(err)
      })
    })
  }, [])
  return (
    <div className='grid grid-cols-1 md:grid-cols-3'>
      {keys&& keys.map((v,i)=> <CareerContainer key={i} datas={datas} cuskey={v}/>)}
    </div>
  )
}

export default CareerResponse