import React from 'react'
import Certificate from '../../../icons/Certificate'
import {setdashboard} from '../../../app/slices/dashboardSlice'
import { useDispatch } from 'react-redux'
import SingleCertificate from '../Single/SingleCertificate'
import MultipleCertificate from '../Multiple/MultipleCertificate'
const InitialContainer = () => {
 const dis = useDispatch()
  return (
    <React.Fragment>
        <p className="text-center text-theme-orange text-3xl font-bold my-3">Generate Certificates</p>
    <div className='grid grid-cols-1 md:grid-cols-2 [&>*]:m-2'>
        <button className="bg-theme-orange rounded-3xl p-20 flex flex-col items-center justify-center" onClick={e=> dis(setdashboard({title:'Auto Certificate',component:<SingleCertificate/>}))}>
            <Certificate h={150} w={150}/>
            <p className='text-xl font-bold text-dashboard-black mt-4'>Single</p>
        </button>
        <button className="bg-theme-orange rounded-3xl p-20 flex flex-col items-center justify-center" onClick={e=> dis(setdashboard({title:'Auto Certificate',component:<MultipleCertificate/>    }))}>
            <Certificate h={150} w={150}/>
            <p className='text-xl font-bold text-dashboard-black mt-4'>Multiple</p>
        </button>
    </div>
    </React.Fragment>
  )
}

export default InitialContainer