import React from "react";

function EmailIcon({h,w}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#FFF27C"
        d="M5 25a2.407 2.407 0 01-1.765-.734A2.408 2.408 0 012.5 22.5v-15c0-.688.245-1.276.735-1.765A2.404 2.404 0 015 5h20c.688 0 1.276.245 1.766.735S27.5 6.812 27.5 7.5v15a2.41 2.41 0 01-.734 1.766A2.41 2.41 0 0125 25H5zm10-8.969c.104 0 .213-.016.328-.047.115-.031.224-.078.328-.14l8.844-5.531c.167-.105.292-.235.375-.39.083-.157.125-.33.125-.517 0-.416-.177-.729-.531-.937-.354-.209-.719-.198-1.094.031L15 13.75 6.625 8.5c-.375-.23-.74-.235-1.094-.016-.354.219-.531.526-.531.922 0 .209.042.39.125.546a.835.835 0 00.375.36l8.844 5.532c.104.062.213.109.329.14.114.031.223.047.327.047z"
      ></path>
    </svg>
  );
}

export default EmailIcon;