import React from "react";

function Google({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>

      <path
        fill="#CFE0FC"
        fillRule="evenodd"
        d="M21.649 17.653c.738.009 1.945.009 2.682 0 .738-.009.134-.016-1.34-.016-1.476 0-2.08.007-1.342.016zm0 10.01c.738.01 1.945.01 2.682 0 .738-.008.134-.015-1.34-.015-1.476 0-2.08.007-1.342.016z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000917"
        fillRule="evenodd"
        d="M25.327 11.016c.57.01 1.504.01 2.074 0 .57-.009.104-.016-1.037-.016-1.14 0-1.607.007-1.037.016zM25.303 34.3c.557.01 1.453.01 1.99 0 .539-.009.083-.016-1.012-.016s-1.535.007-.978.016z"
        clipRule="evenodd"
      ></path>
      <path
        fill={selected?"#555":"#95891E"}
        className="transition-all ease-in-out duration-200"
        fillRule="evenodd"
        d="M20.983 11.012l3.329.02-1.96 3.266a307.784 307.784 0 00-1.961 3.29c0 .013 1.188.024 2.64.024h2.642l1.493 2.489c.82 1.368 1.505 2.487 1.52 2.486.016-.001.92-1.433 2.01-3.181 1.09-1.748 2.002-3.166 2.027-3.15.081.05-2.257-3.959-2.485-4.26-.268-.356-.785-.709-1.249-.852-.336-.103-.58-.109-5.843-.13-3.021-.011-3.995-.012-2.163-.002zm-8.576 6.724c-.198.317-1.973 3.42-2.102 3.677-.396.783-.407 1.655-.03 2.409.176.351 2.105 3.707 2.177 3.787.05.054.888.066 3.968.055l3.905-.014-1.46-2.434c-.804-1.338-1.474-2.474-1.49-2.523-.023-.07 1.523-2.718 2.942-5.04.014-.023-1.743-.041-3.904-.041h-3.928l-.078.124zm14.759 7.453l-1.493 2.488h-2.641c-1.453 0-2.641.011-2.641.024 0 .013.858 1.454 1.908 3.202a231.457 231.457 0 011.94 3.267c.032.08-.267.09-3.193.107-1.775.01-.792.01 2.184-.003 5.131-.02 5.428-.027 5.752-.127a2.97 2.97 0 00.59-.264c.523-.332.751-.648 1.925-2.674.624-1.075 1.136-1.997 1.139-2.047.005-.086-3.89-6.455-3.95-6.46-.015-.001-.7 1.118-1.52 2.487z"
        clipRule="evenodd"
      ></path>
      <path
        fill={selected?"#000":"#FFF27C"}
        className="transition-all ease-in-out duration-200"
        fillRule="evenodd"
        d="M17.123 11.11c-.396.11-.894.4-1.173.684-.182.185-.709 1.044-1.835 2.99a1661.61 1661.61 0 01-1.61 2.78c-.016.027 1.707.048 3.914.048h3.944l3.949-6.58-3.468.003c-2.613.004-3.53.022-3.721.075zm13.59 8.258a236.598 236.598 0 00-1.998 3.228c-.024.061 3.784 6.312 3.946 6.477.06.063 3.079-5.193 3.218-5.603.157-.463.162-1.157.012-1.627-.093-.294-2.954-5.352-3.14-5.55-.056-.06-.432.508-2.037 3.075zm-18.08 8.575l1.564 2.723c1.22 2.127 1.456 2.503 1.755 2.802.365.366.683.557 1.172.702.262.078.736.089 3.72.089 1.88 0 3.418-.019 3.418-.041 0-.023-.877-1.503-1.949-3.29l-1.95-3.25H12.48l.153.265z"
        clipRule="evenodd"
      ></path>
  </svg>
  );
}

export default Google;
