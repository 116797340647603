import {List} from '@mui/material'
import React from 'react'
import Icon from '../../icons/AccessControl'
import Certificate from '../../icons/Certificate'
import Google from '../../icons/Google'
import Images from '../../icons/Images'
import MainWebsite from '../../icons/MainWebsite'
import ManageBlogs from '../../icons/ManageBlogs'
import MoreLinks from '../../icons/MoreLinks'
import ReportBug from '../../icons/ReportBug'
import SpreadSheet from '../../icons/SpreadSheet'
import Upload from '../../icons/Upload'
import MenuListItem from './ListItem/MenuListItem'
import './Menu.css'
import { useDispatch, useSelector } from 'react-redux'
import {setdashboard} from '../../app/slices/dashboardSlice'
import BlogPage from '../BlogPage/BlogPage'
import UploadBlog from '../UploadBlog/UploadBlog'
import AccessControl from '../AccessControl/AccessControl'
import SpreadPage from '../SpreadSheet/SpreadSheet'
import AutoCertificate from '../AutoCertificate/AutoCert'
import Image from '../ContentContainer/Images/Images'
import ReportBugCompo from '../ReportBug/ReportBug'
import GoogleAdmin from '../GoogleAdmin/GoogleAdmin'
import MoreLinksCompo from '../MoreLinks/MoreLinks'
import ProfileBox from './ProfileBox/ProfileBox'
import TopMenu from './TopMenu/TopMenu'
import JobIcon from '../../icons/JobIcon'
import CareerComponent from '../CareerComponent/CareerComponent'
const Menu = () => {
  let selected = useSelector(state=> state.dashboard.title)
  let access = useSelector(state=> state.access)
  let dispath = useDispatch()
  const setselected = (obj)=>{
    dispath(setdashboard(obj))
  }
  console.log(access)
  return (
    <div className='w-full h-[100vh]'>
        <div className="fixed top-0 left-0 bottom-0 h-full bg-theme-black overflow-y-scroll mb-20 z-30 font-semibold">
        <List sx={{height:70,marginBottom:100}}>
          <TopMenu/>
          {access.codes&& Array.isArray(access.codes)&& <>
          {access.codes.map(obj=> obj.access).includes(201)&& <MenuListItem text="Manage Blogs" setselected={setselected} component={<BlogPage/>} src={<ManageBlogs h={35} selected={selected==="Manage Blogs"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(200)&& <MenuListItem text="Upload Blogs" setselected={setselected} component={<UploadBlog/>} src={<Upload h={35} selected={selected==="Upload Blogs"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(230)&& <MenuListItem text="Access Control" setselected={setselected} component={<AccessControl/>} src={<Icon h={35} selected={selected==="Access Control"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(204)&& <MenuListItem text="SpreadSheet" setselected={setselected} component={<SpreadPage/>}  src={<SpreadSheet h={35} selected={selected==="SpreadSheet"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(208)&& <MenuListItem text="Job Recruitment" setselected={setselected} component={<CareerComponent/>} src={<JobIcon className="h-6 w-6" selected={selected==="Job Recruitment"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(206)&& <MenuListItem text="Auto Certificate" setselected={setselected} component={<AutoCertificate/>} src={<Certificate h={35} selected={selected==="Auto Certificate"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(207)&& <MenuListItem text="Images" setselected={setselected} component={<Image/>} src={<Images h={35} selected={selected==="Images"}/>} selected={selected}/>}
          {access.codes.map(obj=> obj.access).includes(199)&& <MenuListItem text="Report Bug" setselected={setselected} component={<ReportBugCompo/>} src={<ReportBug h={35} selected={selected==="Report Bug"}/>} selected={selected}/>}
          
          </>}
           <MenuListItem text="Google Admin" setselected={setselected} component={<GoogleAdmin/>} src={<Google h={35} selected={selected==="Google Admin"}/>} selected={selected}/>
            <a href='https://adsecbd.com' target="_blank" rel="noreferrer">
            <MenuListItem text="Go to Main Website" src={<MainWebsite h={35} selected={selected==="Go to Main Website"}/>} selected={selected}/>
            </a>
            <MenuListItem text="More Links" setselected={setselected} component={<MoreLinksCompo/>} src={<MoreLinks h={35} selected={selected==="More Links"}/>} selected={selected}/>
            
            <ProfileBox setselected={setselected}/>
        </List>
        </div>
    </div>
  )
}

export default Menu