import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { Delete, Edit } from '@mui/icons-material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ButtonGroup, LinearProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addElement, editElement, removeElement } from '../../../../../../app/slices/blogSlice'
import axios from 'axios'

const PictureCompo = ({id,img,arr}) => {
  const [del, setdel] = useState(false)
  const [edit, setedit] = useState(false)
  const [imgt, setimgt] = useState('')
  const [uploading, setuploading] = useState(false)
  let dis = useDispatch()
  let blogs = useSelector(state=> state.blog)
  const removeElem = ()=>{
      const index = Array.from(blogs).map(v=> v.id).indexOf(id)
      dis(removeElement(index))
      setdel(false)
  }
  const saveChanges = ()=>{
    let file = new FormData()
        file.append('material',imgt)
        setuploading(true)
        axios.post(`https://api.adsecbd.com/file`,file).then((res)=>{
          if(res.status===200){
           const index  = Array.from(blogs).map(v=> v.id).indexOf(id)
            console.log(index)
            dis(editElement({index:index+1,obj:{id,html:res.data.url,code:702,compo:<PictureCompo id={id} img={res.data.url}/>}}))
            setuploading(false)
            setedit(false)
            setimgt('')
          }
        }).catch((err)=> console.log(err))
  }
  return (
    <div className='p-3 m-2 rounded-xl bg-dashboard-black flex flex-col items-center'>
        <img src={img} alt='Not found' className='w-52 ring-1 ring-gray-300 rounded-md'/>
        <div className="flex w-full justify-end">
        <IconButton onClick={e=> setedit(true)}>
            <Edit sx={{color:'#FFF27C'}}/>
          </IconButton>
          <IconButton onClick={e=> setdel(true)}>
            <Delete sx={{color:'#FFF27C'}}/>
          </IconButton>
        </div>
        <Dialog open={del} onClose={e=> setdel(false)}>
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Want to Remove?
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <p className='my-2 text-sm text-white'>Want to remove this image?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
           <ButtonGroup variant="contained" size="small" aria-label="">
             <Button color="error" onClick={removeElem}>Remove</Button>
             <Button color="success" onClick={e=> setdel(false)}>Cancel</Button>
           </ButtonGroup>
          </DialogActions>
        </Dialog>
        <Dialog open={edit} fullWidth aria-labelledby="Edit dia">
          <DialogTitle className='bg-dashboard-black text-theme-orange'>
            Edit Image <Edit/>
          </DialogTitle>
          <DialogContent className='bg-dashboard-dark-black'>
            <DialogContentText>
              <div className="mt-5 flex flex-col items-center">
                <div className="mb-5 text-sm text-red-400">Note: Please maintain the ratio 16:9</div>
                <label className='px-5 py-3 rounded-full bg-theme-orange text-dashboard-dark-black font-semibold'>{imgt?"Image Selected":"Select Image"} <input type="file" hidden onChange={e=> setimgt(e.target.files[0])}/> </label>
                <div className="pt-5">
                  {uploading&&<p className='text-center text-gray-300 text-sm italic'>Please wait... the popup will close automatically when the upload will be completed</p>}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='bg-dashboard-dark-black'>
            <ButtonGroup variant="contained" size="small" aria-label="">
              <Button color="success" onClick={saveChanges}>Save</Button>
              <Button color="error" onClick={e=> setedit(false)}>Cancel</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default PictureCompo