import React, { useEffect, useState } from 'react'
import GridContainer from './GridContainer/GridContainer'
import { Fab, Dialog, DialogContent, DialogContentText, DialogActions, Button, AppBar, Toolbar, Typography, IconButton, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText,LinearProgress  } from '@mui/material'
import { AddAPhoto, AttachFile, Close, Image, Upload, UploadFile} from '@mui/icons-material'
import auth from '../../../firebase'
import url from '../../../apiurl'
import axios from 'axios'
const Images = () => {
  const [upload, setupload] = useState(false)
  const [imglist, setimglist] = useState([])
  const [imgs, setimgs] = useState([])
  const [uploading, setuploading] = useState(false)

  const getImages = ()=>{
    auth.currentUser.getIdToken(true).then((token)=>{
      axios.get(`${url}/image`,{
        headers:{
          'Authorization':token
        }
      }).then((res)=>{
        if(res.status===200){
           setimgs(res.data.result)
        }
        else{
          alert('Permission denied!')
        }
      }).catch((err)=>{
        alert('Please check the console')
        console.log(err)
      })
    }).catch((err)=>{
      alert('Please refresh the page.')
    })
  }
  useEffect(() => {
    getImages()
  }, [])
  
  const uploadImages = ()=>{
    
      setuploading(true)
      auth.currentUser.getIdToken(true).then((token)=>{
        const uid = auth.currentUser.uid
          Array.from(imglist).map((v,i)=>{
            const file = new FormData()
            file.append('material',v)
            axios.post('https://api.adsecbd.com/file',file,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((fileurl)=>{
              if(fileurl.status===200){
                axios.post(`${url}/image`,{
                  uid,
                  url:fileurl.data.url,
                  time: new Date().toLocaleString()
                },{
                  headers:{
                    'Authorization':token
                  }
                }).then((res)=>{
                  if(res.status===200){
                    getImages()
                    setimglist((p)=> {
                      let arr = Array.from(p)
                      return arr.splice(arr.indexOf(v),1)
                    })
                  }
                  else{
                    alert('Not authorized!')
                  }
                }).catch((err)=>{
                  alert('Upload Failed')
                })
              }
              
            }).catch((err)=>{
              alert('Error! check console')
              console.log(err)
            })
           
          })
          getImages()
          setuploading(false)
          setimglist([])
          setupload(false)
       
      }).catch(err=>{ alert('Error! check console!');console.log(err)})
    
  }
  return(
    <div>
      <div className="fixed bottom-0 right-0 p-3">

        <Fab variant='extended' sx={{backgroundColor:'#FFF27C'}} onClick={e=> setupload(!upload)}>
          <AddAPhoto sx={{mr:1}}/>
          <p className='text-sm font-semibold'>Upload</p>
        </Fab>
      </div>
        <div className="grid grid-cols-2 md:grid-cols-4 md:px-5 mt-5">
          {imgs&&Array.isArray(imgs)&&imgs.map((v,i)=> <GridContainer key={i} title={`${String(v.time).substring(0,10)}...`} id={v.id} img={v.url} refresh={getImages}/>)}
        </div>
        <Dialog open={upload}>
        <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{backgroundColor:'#111'}}>
          <Typography variant="p" component="div" sx={{ flexGrow: 1 }}>
            Upload Image
          </Typography>
          <IconButton onClick={e=> setupload((e)=> !e)}>
            <Close sx={{color:'#fff'}}/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
          <DialogContent sx={{backgroundColor:"#313131"}}>
            <DialogContentText>
              <center>
                <UploadFile sx={{fontSize:'5em',color:'#FFF27C'}}/><br/>
                <p className="text-center text-theme-orange text-xs mt-3">(You Can Select Multiple Images, Press <span className='text-black bg-theme-orange rounded-md px-2'>Ctrl+Left</span> Click to select multiple files)</p>
                <Button component="label" startIcon={<AttachFile />} variant="contained" size="small" sx={{backgroundColor:'#FFF27C',marginTop:1,color:'#111',":hover":{backgroundColor:'#d1c44b'}}}><input type="file" onChange={e=> imglist.length>1?setimglist((p)=> [...p,...e.target.files]):setimglist(e.target.files)} multiple hidden/>  Select Image</Button>
              </center>
              <List>
              {
                Array.from(imglist).map((v,i)=> <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#111'}}>
                      <Image sx={{color:'#FFF27C'}}/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={<p className='text-theme-orange'>{v&&v.name}</p>} secondary={<p className='text-gray-400'>{v&&(Number(v.size)*0.000001).toFixed(2)} MB</p>}/>
                </ListItem>)}
              </List>
              {uploading&&<LinearProgress sx={{backgroundColor:'#756f34',color:'#FFF27C','& .MuiLinearProgress-bar':{backgroundColor:'#FFF27C'}}} />}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{backgroundColor:'#313131'}}>
              <Button startIcon={<Upload/>} variant="contained" disabled={imglist<1} size="small" sx={{backgroundColor:'#FFF27C',color:'#111',":hover":{backgroundColor:'#d1c44b'},":disabled":{background:'#c8c8c8',color:'#777'}}}  onClick={uploadImages} >Upload</Button>
          </DialogActions>

        </Dialog>
    </div>
  )
}

export default Images