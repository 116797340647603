import { Edit, Newspaper, Publish } from '@mui/icons-material'
import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import CreateCareer from '../CareerDashboard/CareerCreate/CreateCareer'
import CareerResponse from '../CareerResponse/CareerResponse'
import EditCircular from '../EditCircular/EditCircular'

const CareerTab = ({compo,setcompo}) => {
  return (
    <div className='w-full flex items-center justify-end md:px-10'>
        {/* <ButtonGroup variant="contained" color="primary" size='small' aria-label="">
          <Button onClick={e=> setcompo(<BlogContainer/>)} sx={{display:'flex',alignItems:'center'}}><Newspaper sx={{mr:1}}/> Active </Button>
          <Button onClick={e=> setcompo(<ArchivedBlogs/>)}><Archive sx={{mr:1}}/> Archived</Button>
          <Button onClick={e=> setcompo(<DeletedBlogs/>)}><Delete sx={{mr:1}}/> Recycle Bin</Button>
        </ButtonGroup> */}
        <Box sx={{ width: '100%', bgcolor: '#FFF27C',borderRadius:2,mb:5,display:'flex',justifyContent:'center',color:'#FFF27C' }}>
          <Tabs
            value={compo}
            onChange={(e,val)=> setcompo(val)}>
            <Tab icon={<Publish/>} value={<CreateCareer/>} label={<p className='normal-case font-semibold text-dashboard-black'>Publish Circular</p>}/>
            <Tab icon={<Newspaper/>} value={<CareerResponse/>} label={<p className='normal-case font-semibold text-dashboard-black'>Responses</p>} />
            <Tab icon={<Edit/>} value={<EditCircular/>} label={<p className='normal-case font-semibold text-dashboard-black'>Edit</p>}/>
          </Tabs>
      </Box>
    </div>
  )
}

export default CareerTab