import React from "react";

function ProfileIcon({h,w}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#FFF27C"
        d="M15 7.5a4.687 4.687 0 100 9.374A4.687 4.687 0 0015 7.5z"
      ></path>
      <path
        fill="#FFF27C"
        d="M15 1.875A13.125 13.125 0 1028.125 15 13.14 13.14 0 0015 1.875zm7.492 21.493a4.69 4.69 0 00-4.68-4.618h-5.625a4.69 4.69 0 00-4.68 4.618 11.25 11.25 0 1114.986 0z"
      ></path>
    </svg>
  );
}

export default ProfileIcon;