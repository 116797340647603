import React from 'react'
import { useDispatch } from 'react-redux'
import { setdashboard } from '../../../app/slices/dashboardSlice'
import SingleAccess from '../SingleAccess/SingleAccess'

const AccessCard = ({name,image,obj}) => {
  let dispath = useDispatch()
  return (
    <button onClick={e=> dispath(setdashboard({title:"Access Control",component:<SingleAccess obj={obj}/>}))}>
    <div className='m-3 rounded-xl ring-1 ring-gray-600 flex items-center justify-center flex-col px-3 py-8 bg-dashboard-dark-black'>
      <div className="h-32 w-32 overflow-hidden p-1">
        <img src={image&&image} className='h-full w-full object-cover object-center rounded-full ring-2 ring-gray-600' alt="Profile"/>
      </div>
        <p className='text-white text-center mt-5'>{name&&name}</p>
    </div>
    </button>
  )
}

export default AccessCard