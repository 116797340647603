import React from 'react'
import ContentNav from './ContentNav/ContentNav'
import { useSelector } from 'react-redux'


const ContentContainer = () => {
  let compo = useSelector(state => state.dashboard.component)
  return (
    <div className='bg-dashboard-black h-full'>
        <ContentNav/>
        <div className="pt-20 px-10">
          {compo}
        </div>
    </div>
  )
}

export default ContentContainer