import { Delete } from '@mui/icons-material'
import { Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ButtonGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import auth from '../../../../firebase'
import axios from 'axios'
import url from '../../../../apiurl'

const GridContainer = ({title,img,id,refresh}) => {
  const [del, setdel] = useState(false)
  const deleteImage = ()=>{
    if(auth.currentUser!=null){
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.patch(`${url}/image/delete/${id}`,{
          uid: auth.currentUser.uid
        },{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status===200){
            refresh()
            setdel(false)
          }

        })
      }).catch((err)=>{
        alert('Failed to auth, check console')
        console.log(err)
      })
    }
    else{
      alert('User not logged in!')
    }
  }
  useEffect(() => {
    // alert(title)
  }, [])
  
  
  return (
    <>
    <div className='m-1 p-3 bg-dashboard-dark-black rounded-xl bg-opacity-50'>
        <img src={img} alt="" className='ring-2 ring-gray-400 ring-opacity-10 rounded-md' />
        <div className="grid md:grid-cols-5 mt-3"> 
            <div className="col-span-4">
                <p className='text-sm text-white'>{title}</p>
            </div>
            <div className="" onClick={e=> setdel((e)=> !e)}>
              <Avatar sx={{backgroundColor:'#000'}}>
                <IconButton>
                  <Delete sx={{color:'#FFF27C'}}/>
                </IconButton>
              </Avatar>
            </div>
        </div>
    </div>
    <Dialog open={del} onClose={e=> setdel(!del)} aria-labelledby="Delete Dialog">
      <DialogTitle sx={{backgroundColor:'#111'}}>
        <p className='text-white'>Are You Sure?</p>
      </DialogTitle>
      <DialogContent sx={{backgroundColor:'#313131'}}>
        <DialogContentText>
          <p className='text-sm text-gray-300 my-2'>Are you sure that you want to delete this image?</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{backgroundColor:'#313131'}}>
        <ButtonGroup variant="contained" color="primary" size="small">
          <Button color="success" onClick={e=> setdel(!del)}>Cancel</Button>
          <Button color="error" onClick={deleteImage}>Delete</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default GridContainer