import React, { useEffect, useState } from 'react'
import BlogItem from './BlogItem/BlogItem'
import axios from 'axios'
import apiurl from '../../../apiurl'
import auth from '../../../firebase'
import { Newspaper } from '@mui/icons-material'
import { AppBar, Button, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import BlogTop from '../../UploadBlog/Children/BlogTop/BlogTop'
import BlogBody from '../../UploadBlog/Children/BlogBody/BlogBody'
import url from '../../../apiurl'
import { useDispatch, useSelector } from 'react-redux'
import { addElement, clearblogs } from '../../../app/slices/blogSlice'
import ButtonCompo from '../../UploadBlog/Children/BlogBody/Tools/Button/ButtonCompo'
import PictureCompo from '../../UploadBlog/Children/BlogBody/Tools/Image/PictureCompo'
import HTMLCompo from '../../UploadBlog/Children/BlogBody/Tools/HTML/HTMLCompo'
import TextComponent from '../../UploadBlog/Children/BlogBody/Tools/Text/TextComponent'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlogContainer = () => {
  const [blogs, setblogs] = useState([])
  const [edit, setedit] = useState(false)
  const uelems = useSelector(state=> state.blog)
  const dis = useDispatch()

  //edit states
  const [title, settitle] = useState('')
  const [feat, setfeat] = useState('')
  const [items, setitems] = useState([])
  const [author, setauthor] = useState('')
  const [date, setdate] = useState('')
  const [bid, setbid] = useState('')

  const editBlog = (state,index)=>{
    const blog = blogs[index]
    
    settitle(blog.title)
    setfeat(blog.featured)
    setauthor(blog.author)
    setdate(blog.date)
    setbid(blog.bid)
    dis(clearblogs())
    auth.currentUser.getIdToken(true).then(token=>{
      axios.get(`${url}/blogs/elem/${blog.bid}`,{
        headers:{
          'Authorization':token
        }
      }).then((res)=>{
        if(res.status===200){
          console.log(res.data.result)
          Array.from(res.data.result).map((v,i)=>{
            dis(
              addElement({
                ...v,
                compo:
                (v.code==701?
                <ButtonCompo
                id={i+1}
                key={i}
                link={String(v.html)
                  .substring(
                    String(v.html).indexOf("%link:%")
                    ,String(v.html).length)
                  .replace("%link:%",'')} 
                text={String(v.html)
                  .substring(0,String(v.html)
                  .indexOf("%link:%"))}/>
                :(v.code===702?<PictureCompo id={i+1} img={v.html} alt="Unknown Error!" key={i}/>
                :(v.code===703?<TextComponent text={v.html} id={i+1} key={i}/>
                :(v.code===704&&<HTMLCompo id={i+1} html={v.html} key={i}/>))))}))
          })
          setedit(state)
        }
        else{
          console.log(res.data)
        }
      }).catch((err)=> console.log(err))
    }).catch((err)=> console.log(err))
  
  }

  const closeEdit = ()=>{
    settitle('')
    setfeat('')
    setauthor('')
    setdate('')
    setitems([])
    setedit(false)
    dis(clearblogs())
  }

  const getBlogs = ()=>{
    if(auth.currentUser!==null){
    auth.currentUser.getIdToken(true).then((token)=>{
      axios.get(`${apiurl}/blogs`,{
        headers:{
          'Authorization': token
        }
      }).then((res)=>{
        if(res.status===200){
          setblogs(res.data.result)
        }
        else{ 
          console.log('IDK bro')
        }
      }).catch((err)=> console.log(err))
    }).catch((err)=> console.log(err))
  }
  else{
    setTimeout(() => {
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.get(`${apiurl}/blogs`,{
          headers:{
            'Authorization': token
          }
        }).then((res)=>{
          if(res.status===200){
            setblogs(res.data.result)
          }
          else{ 
            console.log('IDK bro')
          }
        }).catch((err)=> console.log(err))
      }).catch((err)=> console.log(err))
    }, 3000);
  }
  }
  useEffect(() => {
    getBlogs()
  }, [])
  const updateBlogs = ()=>{
    if(auth.currentUser!=null){
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.patch(`${url}/blogs`,{
          title,
          featured:feat,
          blogid:bid,
          body:uelems
        },{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          console.log(res)
          setedit(false)
        }).catch((err)=> console.log(err))
      })
    }
   
    
  }
  return (
    <div>
      <h1 className='text-theme-orange text-3xl font-semibold mb-10'><Newspaper sx={{fontSize:30}}/> Visible Blogs</h1>
      {Array.isArray(blogs)&& blogs.map((v,i)=> {
        if(parseInt(v.state)===298){
          return(
      <BlogItem 
        title={v.title} 
        slug={v.slug} 
        feature={v.featured} 
        desc={`Author: ${v.author}`}
        author={v.author}
        time={v.date}
        key={i}
        index={i}
        setedit={editBlog}
        getBlogs={getBlogs}
        id={v.id}/>)
        }
      }
      )
    }
      
      <Dialog
        fullScreen
        open={edit}
        onClose={e=> setedit(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative',backgroundColor:'rgb(32,32,32)' }} className='bg-dashboard-black'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeEdit}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Blog
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="bg-[#333] h-[100vh] overflow-y-scroll p-5">
          <BlogTop feat={feat} setfeat={setfeat} top={title} settop={settitle}/>
          <p className="text-white text-sm my-4">Author :   <span className='text-gray-400 italic'>{author}</span></p>
          <BlogBody body={items} setbody={setitems} upblog={updateBlogs}/>
        </div>
      </Dialog>
    </div>
  )
}

export default BlogContainer