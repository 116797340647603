import React, {  useEffect, useState } from 'react'
import Enconiya from '../../icons/Enconiya'
import { AttachFile, Celebration, Image, Photo } from '@mui/icons-material'
import gif from '../../img/uploading.gif'
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import axios from 'axios'
import url from '../../apiurl'

const ReportBug = () => {
    const [title, settitle] = useState('')
    const [desc, setdesc] = useState('')
    const [photos, setphotos] = useState([])
    const [docs, setdocs] = useState([])
    const [uploading, setuploading] = useState(false)
    const [uploaded, setuploaded] = useState(false)
    const [links, setlinks] = useState([])
    useEffect(()=>{
        console.log(photos)
        photos.map((v)=>{
            console.log(v)
        })
    },[photos])
    useEffect(()=>{
        console.log(docs)
        docs.map((v)=>{
            console.log(v)
        })
    },[docs])
    useEffect(() => {
      console.log(links)
    }, [links])
    
    const sendReport = ()=>{
        if(!title){
            alert('Please determine the location')
        }
        else if(!desc){
            alert('Please enter a description')
        }
        else{
            if(photos.length>=1 || docs.length>=1){
                console.log('Inside the if block')
                setuploading(true)
                photos.map((v)=>{
                    const form = new FormData()
                    form.append('material',v)
                    axios.post(`https://api.adsecbd.com/file`,form).then((res)=>{
                        setlinks((p)=> [...p,res.data.url])
                    })
                    .catch((err)=>{
                        console.log(err)
                        alert('maybe the server is down')
                    })
                })
                // if(docs.length>=1){
                //     const form = new FormData()
                //     docs.map((v)=>{
                //         form.append('material',v)
                //     })
                //     axios.post(`https://api.adsecbd.com/file`,form).then((res)=>{
                //         setlinks((p)=> [...p,res.data.url])
                //         setdocs([])
                //         setuploading(false) 
                //     }).catch((err)=>{
                //         console.log(err)
                //         setuploading(false)
                //         alert('maybe the server is down')
                //     })
                // }
            }
           
            // axios.post(`${url}/report`,{
            //     title,
            //     desc,
            //     links
            // }).then((res)=>{
            //     if(res.status===200){
            //         setuploading(false)
            //         setuploaded(true)
            //     }
            //     else{
            //         alert('API Error')
            //     }
            // })
        }
       
        
    }
  return (
    <div className='py-10'>
        <div className="grid grid-cols-1 md:grid-cols-10 [&>*]:mx-2">
            <div className="col-span-7">
                <label htmlFor="bugLocation" className='text-sm text-white'>Bug Location</label> <br />
                <input type="text" id='bugLocation' className='mt-1 rounded-lg text-white bg-dashboard-dark-black px-3 py-3 w-full' value={title} onChange={e=> settitle(e.target.value)}/>
            </div>
            <div className="col-span-3 rounded-lg flex items-end justify-center">
                <div className="bg-dashboard-dark-black rounded-lg w-full py-3 flex items-center justify-center">
                    <p className='text-white mr-3 text-sm'>Reporting to:</p>
                    <Enconiya h={25}/>
                </div>
            </div>
            <div className="col-span-10 mt-10">
            <label htmlFor="bugDetails" className='text-sm text-white'>Bug Details</label> <br />
            <textarea id="bugDetails" rows="13" className='mt-1 rounded-lg text-white bg-dashboard-dark-black px-3 py-3 w-full' value={desc} onChange={e=> setdesc(e.target.value)}></textarea>
            </div>
            <div className="col-span-10 mt-10">
            <div className="bg-dashboard-dark-black rounded-lg w-full py-3 grid grid-cols-1 md:grid-cols-10 [&>*]:items-center [&>*]:flex px-5">
                    <div className='col-span-2 justify-center'>
                        <Button variant="text" sx={{display:'flex'}} color="inherit" component="label">
                            <Photo sx={{color:'#FA916B'}}/>                      
                            <input type="file" id="" hidden onChange={e=> setphotos((p)=> [...p,e.target.files[0]])}/>
                            <p className='mx-2 text-white text-sm'>Add Photo</p>
                        </Button>
                    </div>
                    <div className='col-span-2 justify-center'>
                        <Button variant="text" sx={{display:'flex'}} color="inherit" component="label">
                            <AttachFile sx={{color:'#FA916B'}}/>         
                            <input type="file" id="" hidden onChange={e=> setdocs((p)=>  [...p,e.target.files[0]])}/>             
                            <p className='mx-2 text-white text-sm'>Add Document</p>
                        </Button>
                    </div>
                   
                    <div className="col-span-4 justify-end">
                        
                        <button className='px-10 py-3 rounded-full bg-theme-orange text-dashboard-dark-black transition-all ease-in-out duration-200 hover:bg-black hover:text-theme-orange' onClick={sendReport}>Send</button>
                    </div>
                  
                </div>
                
            </div>
            <div className="col-span-10">
            <List>
                {Array.isArray(photos)&&photos.map((v,i)=><ListItem key={i} sx={{display:'flex'}}>
                    <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#111'}}>
                        <Image sx={{color:'#FA916B'}}/>
                    </Avatar>
                    <ListItemText primary={<p className='text-theme-orange'>{v&&v.name}</p>} secondary={<p className='text-gray-400'>{v&&(Number(v.size)*0.000001).toFixed(2)} MB</p>}/>
                    </ListItemAvatar>
                </ListItem>)}
                {Array.isArray(docs)&&docs.map((v,i)=><ListItem key={i} sx={{display:'flex'}}>
                    <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#111'}}>
                        <AttachFile sx={{color:'#FA916B'}}/>
                    </Avatar>
                    <ListItemText primary={<p className='text-theme-orange'>{v&&v.name}</p>} secondary={<p className='text-gray-400'>{v&&(Number(v.size)*0.000001).toFixed(2)} MB</p>}/>
                    </ListItemAvatar>
                </ListItem>)}
                    
                </List>
            </div>
        </div>
       <Dialog open={uploading} aria-labelledby="Uploading Button">
         <DialogTitle className='bg-dashboard-black text-theme-orange'>
           Uploading
         </DialogTitle>
         <DialogContent className='bg-dashboard-dark-black'>
           <DialogContentText sx={{color:'#d7d7d7',py:4}}>
            <center>
            <img src={gif} height="200px" width="200px" alt="Uploading GIF"/>
             Uploading attached files
             <br/> <br/> <p className='text-gray-400 text-xs'>This popup will be automatically closed when the uploading process completes. Please wait & don't close your browser! </p>
             </center>
           </DialogContentText>
         </DialogContent>
       </Dialog>
       <Dialog open={uploaded} aria-labelledby="Uploading Button">
         <DialogTitle className='bg-dashboard-black text-theme-orange'>
           Report Sent <Celebration/>
         </DialogTitle>
         <DialogContent className='bg-dashboard-dark-black'>
           <DialogContentText sx={{color:'#d7d7d7',pt:4}}>
                Report sent to Enconiya Successfully!
           </DialogContentText>
         </DialogContent>
         <DialogActions className='bg-dashboard-dark-black'>
            <button onClick={e=> setuploaded((p)=>!p)} className='px-5 bg-theme-orange font-semibold py-2 rounded-full text-sm'>
                Okay
            </button>
         </DialogActions>
       </Dialog>
    </div>
  )
}

export default ReportBug