import React from "react";

function Icon({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
    <path
      fill={selected?"#313131":"#FFF27C"} className="transition-all duration-300 ease-in-out"
      d="M11.75 29.5h9.375v1.875H11.75V29.5zm0-5.625h9.375v1.875H11.75v-1.875zm20.625-3.75h-18.75a1.875 1.875 0 01-1.875-1.875v-5.625a1.875 1.875 0 011.875-1.875h18.75a1.875 1.875 0 011.875 1.875v5.625a1.875 1.875 0 01-1.875 1.875zm-18.75-7.5v5.625h18.75v-5.625h-18.75zm18.75 20.625H26.75a1.875 1.875 0 01-1.875-1.875V25.75a1.875 1.875 0 011.875-1.875h5.625a1.875 1.875 0 011.875 1.875v5.625a1.875 1.875 0 01-1.875 1.875zm-5.625-7.5v5.625h5.625V25.75H26.75z"
    ></path>
  </svg>
  );
}

export default Icon;
