import { Divider, ListItem, ListItemIcon } from '@mui/material'
import React from 'react'

const MenuListItem = ({src,text,setselected,selected,component}) => {
  return (
    <>
    <ListItem button onClick={()=> setselected({title:text,component})} style={{background:selected===text&&'#000'}}>
        <ListItemIcon>
            {src}
        </ListItemIcon>
        <p className={`text-sm ${selected===text?'text-theme-orange font-normal':'text-white font-normal'}`}>{text}</p>
    </ListItem>
    <Divider sx={{mx:3,my:1,background:'#4d4d4d'}}/>
    </>
  )
}

export default MenuListItem