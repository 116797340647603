import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import Items from '../../../../Components'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { Image} from '@mui/icons-material'
import { Button, ButtonGroup, LinearProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { addElement } from '../../../../../../app/slices/blogSlice'
import PictureCompo from './PictureCompo'

const Picture = ({text}) => {
  const [picture, setpicture] = useState(false)
  const [img, setimg] = useState('')
  const [uploading, setuploading] = useState(false)
  const dis = useDispatch()
  let blogs = useSelector(state=> state.blog)
    const [{ opacity }, dragRef] = useDrag(
        () => ({
          type: Items.TOOL,
          item: { text },
          end:(item,monitor)=>{
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
              setpicture(true)
            }
          },
          collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1
          })
        }),
        []
      )
      const addImage = ()=>{
        let file = new FormData()
        file.append('material',img)
        setuploading(true)
        axios.post(`https://api.adsecbd.com/file`,file).then((res)=>{
          if(res.status===200){
            console.log(res.data.url)
            dis(addElement({id:Array.from(blogs).length+1,html:res.data.url,code:702,compo:<PictureCompo id={Array.from(blogs).length+1} img={res.data.url}/>}))
            setuploading(false)
            setpicture(false)
            setimg('')
          }
        }).catch((err)=> console.log(err))
      }
  return (
    <button className='flex flex-col items-center' ref={dragRef} style={{ opacity}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      fill="none"
      viewBox="0 0 46 46"
    >
      <circle cx="23" cy="23" r="23" fill="#FFF27C"></circle>
      <path
        fill="#0D0D0D"
        d="M27.688 19.719a1.406 1.406 0 01-2.403.996 1.394 1.394 0 01-.41-.996 1.406 1.406 0 012.813 0zm7.5-5.157v16.876a1.875 1.875 0 01-1.876 1.875H12.689a1.875 1.875 0 01-1.876-1.876V14.563a1.875 1.875 0 011.876-1.874h20.624a1.875 1.875 0 011.876 1.874zm-1.876 12.739V14.563H12.689v10.863l4.3-4.301a1.887 1.887 0 012.649 0l5.238 5.238 2.426-2.425a1.887 1.887 0 012.648 0l3.363 3.363z"
      ></path>
    </svg>
    <p className='text-center text-xs text-theme-orange uppercase mt-2'>Picture</p>
    <Dialog open={picture} onClose={e=> setpicture(false)} aria-labelledby="">
      <DialogTitle className='bg-dashboard-black text-theme-orange'>
        Add Picture
      </DialogTitle>
      <DialogContent className='bg-dashboard-dark-black'>
        <DialogContentText>
          
          <center>
            <Image sx={{color:'#d7d7d7',fontSize:80,mt:3}}/>
            <p className='text-center text-white text-sm my-3'>You have to upload the picture first (16:9 ratio)</p>
            <label className='px-5 py-2 rounded-xl bg-theme-orange font-semibold cursor-pointer transition-all ease-in-out duration-300 hover:bg-opacity-80'>Upload <input type="file" onChange={e=> setimg(e.target.files[0])} hidden/> </label>
            <div className="mt-5"></div>
            {img&&<p className='text-xs text-gray-500 mb-2 italic'>Selected Image: {img.name}</p>}
             {uploading&&<div className="pt-5">
              <LinearProgress/>
            </div>}
            
          </center>
        </DialogContentText>
      </DialogContent>
      <DialogActions className='bg-dashboard-dark-black'>
        <ButtonGroup variant="contained" aria-label="" size="small" sx={{mt:2}}>
          <Button color="success" onClick={addImage}>Upload</Button>
          <Button onClick={e=> setpicture(false)} color="error">Cancel</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
    </button>
  )
}

export default Picture