import React, { useEffect } from 'react'
import KeyIcon from '../../../../icons/KeyIcon'
import AccessChip from './AccessChip/AccessChip'
import access from './AccessChip/access.json'
const AccessBox = ({arr}) => {
  useEffect(() => {
    console.log(Object.keys(access))
  }, [])
  
  return (
    <div className='my-3 mt-20 mx-3 rounded-lg bg-dashboard-dark-black p-4 md:px-7 w-full'>
        <div className="flex items-center w-full">
            <KeyIcon w={30} h={30}/>
            <p className='text-white mx-3 text-xl font-medium'>Access</p>
        </div>
        <div className="flex flex-wrap my-10">
          
            {Array.isArray(arr)&&arr.map((v,i)=> Object.keys(access).includes(String(v.access))&&<AccessChip key={i} text={access[v.access].access}/>)}
        </div>
    </div>
  )
}

export default AccessBox