import { Download, Link } from '@mui/icons-material'
import React, { useState } from 'react'
import { useEffect } from 'react'

const CareerContainer = ({datas,cuskey}) => {
  const [data, setdata] = useState([])
  useEffect(() => {
    if(Array.isArray(datas)){
        setdata(datas.filter((v)=> v.resid==cuskey))
        console.log(datas.filter((v)=> v.resid==cuskey))    
    }
  }, [])
  
  return (
    <div className="p-3">
    <div className='w-full border border-gray-500 rounded-xl py-3 px-5 grid grid-cols-5'>
        {data&&data.map((v,i)=> v.reskey!=="cv"&&<><div className="text-sm text-white font-normal mt-2 col-span-2">{v.reskey}</div>
        <p className='text-theme-orange col-span-3 mt-2 text-sm my-auto'>{v.resval}</p></>)}
        <div className="col-span-5 flex justify-end mt-3">
            {data&&data.map((v)=> v.reskey==="cv"&& <a href={v.resval} target='_blank' rel='noreferrer'><button className='mx-2 rounded-full bg-theme-orange hover:bg-theme-orange/90 transition-all ease-in-out duration-300 px-3 py-1 text-xs'>Download CV <Download/> </button></a>)}
            
            
        </div>
    </div>
    </div>
  )
}

export default CareerContainer