import { Close, Image, Upload } from '@mui/icons-material'
import axios from 'axios'
import React, { useState } from 'react'
import { Button } from '@mui/material'

const BlogTop = ({top,settop,feat,setfeat}) => {
  const [uploading, setuploading] = useState(false)
  const [img, setimg] = useState('')
  const uploadFeat = ()=>{
    let file = new FormData()
        file.append('material',img)
        setuploading(true)
        axios.post(`https://api.adsecbd.com/file`,file).then((res)=>{
          if(res.status===200){
            setfeat(res.data.url)
            setuploading(false)
            setimg('')
          }
        }).catch((err)=> console.log(err))
  }
  return (
    <>
    <div className='m-1'>
        <span className='text-white text-sm'>Blog Title</span>
        <input type="text" className='w-full bg-transparent px-5 rounded-xl py-4 mt-2 ring-1 ring-theme-orange text-white' value={top} onChange={e=> settop(e.target.value)} />
    </div>
    <div className='mt-5 mx-1'>
        <span className='text-white text-sm'>Featred Image (The image as blog cover) <span className='text-red-400'>Must Upload</span></span>
        {feat?<><img src={feat} className='w-[50%] rounded-xl mt-5' alt='Error'/><center> <Button sx={{mt:2}} onClick={e=> setfeat('')} variant="text" color="error">
          <Close/> Remove Image
        </Button> </center></>:<div className='w-full flex justify-center'>
          <div className="w-80 mt-6 bg-dashboard-dark-black rounded-xl p-3 flex flex-col items-center">
            <Image sx={{color:'#FFF27C',fontSize:100}}/>
            {!img?<div className="my-10">
              <label className='px-5 py-3 bg-theme-orange rounded-full font-semibold'>{img?'Image Selected':'Select Image'} <input type="file" hidden onChange={e=> setimg(e.target.files[0])} /></label>
            </div>:<button className="mt-3 bg-theme-orange px-5 py-3 rounded-full font-semibold" onClick={uploadFeat}><Upload/> {uploading?'Uploading...':'Upload'}</button>}
          </div>
          </div>}
        
    </div>
    </>
  )
}

export default BlogTop