import React from 'react'
import SpreadSheet from '../../../icons/SpreadSheet'

const SpreadSheetItem = ({title,subtitle,link}) => {
  return (
    <a href={link} target="_blank" rel='noreferrer'>
    <div className='flex flex-col justify-center items-center'>
      <div className="bg-[#313131] p-5  h-36 w-36 rounded-3xl">
      <SpreadSheet h={100}/>
      </div>
      <p className='text-white text-center mt-3'>{title}</p>
      <p className='text-gray-500 text-xs text-center'>{subtitle}</p>
    </div>
    </a>
  )
}

export default SpreadSheetItem