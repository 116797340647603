import React from "react";

function GoogleConsole({h,w}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 156 140"
    >
      <path
        fill="#1967D2"
        d="M15.629 99.75l18.104 31.36a16.022 16.022 0 0013.877 8.015h38.553l-6.94-21.061L62.547 99.75l-23.458-4.078-23.46 4.078zM153.462 61.985l-17.579-30.45-15.12 16.738L111.838 70l9.695 23.249 14.236 15.4 17.693-30.634a16.042 16.042 0 000-16.03zM86.171.875H47.62A16.022 16.022 0 0033.74 8.89L15.63 40.25l23.459 4.077 23.458-4.077L79.67 21.936 86.171.875z"
      ></path>
      <path
        fill="#4285F4"
        d="M44.693 70l17.85-29.75H15.625L3.078 61.985a16.04 16.04 0 000 16.03L15.625 99.75h46.918L44.693 70z"
      ></path>
      <path
        fill="#4285F4"
        d="M93.903 40.25L111.753 70l24.036-38.465L122.716 8.89A16.021 16.021 0 00108.839.875H86.08L62.455 40.25h31.448z"
      ></path>
      <path
        fill="#4285F4"
        d="M93.903 99.75H62.455l23.625 39.375h22.759a16.022 16.022 0 0013.877-8.015l12.968-22.461-23.931-38.65-17.85 29.75z"
      ></path>
    </svg>
  );
}

export default GoogleConsole;
