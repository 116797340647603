import React from "react";

function MoreLinks({h,w,selected}) {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={w}
        height={h}
        fill="none"
        viewBox="0 0 45 45"
    >
        <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>

      <g clipPath="url(#clip0_312_4590)">
        <path
          fill={selected?"#313131":"#FFF27C"}
          className="transition-all duration-300 ease-in-out"
          d="M23 9.5c6.904 0 12.5 5.596 12.5 12.5S29.904 34.5 23 34.5 10.5 28.904 10.5 22 16.096 9.5 23 9.5zm5.625 10.625a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm-5.625 0a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm-5.625 0a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_312_4590">
          <path fill="#fff" d="M0 0H30V30H0z" transform="translate(8 7)"></path>
        </clipPath>
      </defs>
    </svg>
    );
}

export default MoreLinks;
