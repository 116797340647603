import React from 'react'
import SpreadSheetItem from './SpreadSheetItem/SpreadSheetItem'

const SpreadSheet = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-6'>
      <SpreadSheetItem title="Subscriber list" subtitle="Ref : Home" link="https://docs.google.com/spreadsheets/d/1kQp3vA7sWyOMNh7dE0k4V7jeclsMwW20ByKLDcYwPXI/edit?usp=sharing"/>
      <SpreadSheetItem title="Registration" subtitle="Ref : Registration" link="https://docs.google.com/spreadsheets/d/1-0JafOcv0xdNKwrfN33mayQ6xq3JNv9oXdLapbRY774/edit?usp=sharing"/>
      <SpreadSheetItem title="Inquiry by web" subtitle="Ref : contact" link="https://docs.google.com/spreadsheets/d/1LE0qrQlh9i94FXf5uW8wCzE1NPJLWn3mDSnNqQ8KHk4/edit?usp=sharing"/>
    </div>
  )
}

export default SpreadSheet