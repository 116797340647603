import React from "react";

function ArrowIcon({w,h,mode}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={mode=="light"?"#000":"#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h17M13 5l7 7-7 7"
      ></path>
    </svg>
  );
}

export default ArrowIcon;
