import React from "react";

function MainWebsite({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
    <path
      fill={selected?"#313131":"#FFF27C"} className="transition-all duration-300 ease-in-out"
      d="M25.025 24.6l2.05 2.825c.037-.675.15-1.338.375-2.012.225-.7.55-1.338.925-1.9l-3.35 1.087zm5.187 7.15c-.025-1.65.225-3.413.8-5.188.588-1.787 1.438-3.387 2.438-4.7l-2.55.826c-.975.637-1.813 1.737-2.275 3.1-.413 1.325-.4 2.662 0 3.737l1.587 2.225zm1.85 2.5a11.79 11.79 0 01-2.025 1.5l-.4-.55c-1.762-.95-4.087-1.575-6.637-1.575s-4.875.625-6.638 1.575l-.4.55a11.79 11.79 0 01-2.025-1.5l.313-.388c.412-2.012.275-4.462-.525-6.937-.8-2.438-2.1-4.475-3.588-5.85l-.55-.175c.188-.825.45-1.65.775-2.387l.5.162c2.038-.213 4.313-1.113 6.425-2.637 2.075-1.513 3.613-3.376 4.463-5.213v-.587l1.25-.05 1.25.05v.524c.837 1.875 2.387 3.775 4.5 5.3 2.075 1.513 4.325 2.4 6.337 2.638l.55-.188c.325.738.588 1.563.775 2.388l-.625.225C34.3 22.475 33 24.512 32.212 26.95c-.787 2.413-.937 4.8-.562 6.762l.412.538zM21.75 22.212v-3.587a8.106 8.106 0 01-1.525 1.462c-.613.413-1.263.776-1.913 1.038l3.438 1.087zm0-8.937c-.938 1.362-2.2 2.662-3.75 3.775a15.594 15.594 0 01-4.775 2.4l2.462.8c1.2.1 2.588-.3 3.8-1.175 1.175-.85 1.963-1.988 2.263-3.125v-2.675zm2.5 8.937l3.425-1.087a8.465 8.465 0 01-1.863-1.012 8.477 8.477 0 01-1.562-1.488v3.587zm8.5-2.762c-1.588-.475-3.213-1.262-4.75-2.375-1.538-1.125-2.8-2.438-3.75-3.825v2.588c.275 1.175 1.075 2.374 2.3 3.262 1.162.85 2.487 1.237 3.662 1.175l2.538-.825zM20.975 24.6l-3.425-1.113c.387.563.7 1.2.925 1.9.237.738.35 1.438.375 2.138l2.125-2.925zm-8.5-2.763c1 1.313 1.85 2.913 2.437 4.7.588 1.826.838 3.638.8 5.313l1.513-2.088c.475-1.137.525-2.562.062-3.987-.45-1.375-1.287-2.475-2.275-3.112l-2.537-.826zM23 26.063L20.937 28.9a7.9 7.9 0 014.125 0L23 26.063zm-5.2 7.162c1.55-.538 3.325-.85 5.2-.85s3.65.313 5.2.85l-1.6-2.2c-.9-.712-2.188-1.15-3.6-1.15-1.413 0-2.7.438-3.6 1.15l-1.6 2.2z"
      ></path>
  </svg>
  );
}

export default MainWebsite;
