import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import Items from '../../../../Components'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import * as muiButton from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import { addElement } from '../../../../../../app/slices/blogSlice'
import ButtonCompo from './ButtonCompo'

const Button = ({text}) => {
  const [open, setopen] = useState(false)
  const [button, setbutton] = useState('')
  const [link, setlink] = useState('')
  let blogs = useSelector(state=> state.blog)
  const dis = useDispatch()
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: Items.TOOL,
      item: { text },
      end:(item,monitor)=>{
        const dropResult = monitor.getDropResult()
        if (item && dropResult) {
          setopen(true)
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )
  const addElem = ()=>{
    dis(addElement({id:Array.from(blogs).length+1,html:`${button}%link:%${link}`,code:701,compo:<ButtonCompo id={Array.from(blogs).length+1} text={button} link={link}/>}))
    setopen(false)
    setbutton('')
    setlink('')
  }
  return (
    <button className='flex flex-col items-center' ref={dragRef} style={{ opacity}}>
    <svg
       xmlns="http://www.w3.org/2000/svg"
       width="47"
       height="46"
       fill="none"
       viewBox="0 0 47 46"
     >
       <circle cx="23.5" cy="23" r="23" fill="#FFF27C"></circle>
       <path
         fill="#0D0D0D"
         d="M29.75 16.75H26c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25h3.75A3.761 3.761 0 0133.5 23a3.761 3.761 0 01-3.75 3.75H26c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25h3.75c3.45 0 6.25-2.8 6.25-6.25s-2.8-6.25-6.25-6.25zM18.5 23c0 .688.563 1.25 1.25 1.25h7.5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25h-7.5c-.688 0-1.25.563-1.25 1.25zm2.5 3.75h-3.75A3.761 3.761 0 0113.5 23a3.761 3.761 0 013.75-3.75H21c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25h-3.75C13.8 16.75 11 19.55 11 23s2.8 6.25 6.25 6.25H21c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25z"
       ></path>
     </svg>
 <p className='text-center text-xs text-theme-orange uppercase mt-2'>Button</p>
 <Dialog open={open} onClose={e=> setopen(false)} aria-labelledby="">
   <DialogTitle className='bg-dashboard-black text-theme-orange'>
     Add Button
   </DialogTitle>
   <DialogContent className='bg-dashboard-dark-black'>
     <DialogContentText>
       <p className='my-3 text-xs text-gray-500 italic'>*Enter the button link and text and then press Add</p>
       <div className="py-2">
        <label htmlFor="#title" className='text-sm text-theme-orange'>Button Text</label>
        <input type="text" id='title' className='ring-1 ring-theme-orange px-3 py-3 text-white rounded-xl bg-transparent w-full' value={button} onChange={e=> setbutton(e.target.value)}/>
       </div>
       <div className="py-2">
        <label htmlFor="#link" className='text-sm text-theme-orange'>Button Link</label>
        <input type="text" id='link' className='ring-1 ring-theme-orange px-3 py-3 text-white rounded-xl bg-transparent w-full' value={link} onChange={e=> setlink(e.target.value)}/>
       </div>
     </DialogContentText>
   </DialogContent>
   <DialogActions className='bg-dashboard-dark-black'>
     <ButtonGroup variant="contained"aria-label="" size="small">
      <muiButton.default color="success" onClick={addElem}>Add</muiButton.default>
      <muiButton.default color="error" onClick={e=> setopen(false)}>Cancel</muiButton.default> 
     </ButtonGroup>
   </DialogActions>
 </Dialog>
 </button>
  )
}

export default Button