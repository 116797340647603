import { Archive } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import auth from '../../../firebase'
import axios from 'axios'
import url from '../../../apiurl'
import ArchiveItem from './ArchiveItem/ArchiveItem'

const ArchivedBlogs = () => {
  const [blogs, setblogs] = useState([])
  const getBlogs = ()=>{
    if(auth.currentUser!==null){
    auth.currentUser.getIdToken(true).then((token)=>{
      axios.get(`${url}/blogs`,{
        headers:{
          'Authorization': token
        }
      }).then((res)=>{
        if(res.status===200){
          setblogs(res.data.result)
        }
        else{ 
          console.log('IDK bro')
        }
      }).catch((err)=> console.log(err))
    }).catch((err)=> console.log(err))
  }
  else{
    setTimeout(() => {
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.get(`${url}/blogs`,{
          headers:{
            'Authorization': token
          }
        }).then((res)=>{
          if(res.status===200){
            setblogs(res.data.result)
          }
          else{ 
            console.log('IDK bro')
          }
        }).catch((err)=> console.log(err))
      }).catch((err)=> console.log(err))
    }, 3000);
  }
  }
    useEffect(() => {
      getBlogs()
    }, [])
    
  return (
    <div>
        <h1 className='text-theme-orange text-3xl font-semibold mb-10'><Archive sx={{fontSize:30}}/> Archived Blogs</h1>
        {Array.isArray(blogs)&& blogs.map((v,i)=>{
          if(parseInt(v.state)===399){
            return(
        <ArchiveItem
          title={v.title} 
          slug={v.slug} 
          feature={v.featured} 
          desc={`Author: ${v.author}`}
          author={v.author}
          time={v.date}
          key={i}
          getBlogs={getBlogs}
          id={v.id}/>)
          }
        })}
    </div>
  )
}

export default ArchivedBlogs