import { configureStore } from "@reduxjs/toolkit";
import slice from './slices/userSlice'
import dashboard from "./slices/dashboardSlice";
import accessSlice from "./slices/accessSlice";
import blogSlice from "./slices/blogSlice";
export const store = configureStore({
    reducer:{
        user: slice,
        dashboard: dashboard,
        access:accessSlice,
        blog:blogSlice
    }
})