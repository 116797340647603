import React, { useEffect, useState } from 'react'
import AccessCard from './AccessCard/AccessCard'
import auth from '../../firebase'
import axios from 'axios'
import url from '../../apiurl'
import { AppBar, Button, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Toaster, toast } from 'react-hot-toast'
import OTPInput from 'react-otp-input'
import UserIcon from '../../icons/userIcon'
import AccessSwitch from './SingleAccess/AccessSwitch/AccessSwitch'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AccessControl = () => {
  const [users, setusers] = useState([])
  const [userdia, setuserdia] = useState(false)
  //step state
  const [step, setstep] = useState(0)
  //new user details
  const [nuname, setnuname] = useState('')
  const [nupos, setnupos] = useState('')
  const [nupass, setnupass] = useState('')
  const [nurepass, setnurepass] = useState('')
  const [nuemail, setnuemail] = useState('')
  const [nucontact, setnucontact] = useState('')
  const [show, setshow] = useState(false)
  //otp states 
  const [otp, setotp] = useState('')
  const [iotp, setiotp] = useState()
  //image states
  const [imgup, setimgup] = useState(false)
  const [imglink, setimglink] = useState('')
  //access array
  const [accarr, setaccarr] = useState([])
  const getUsers = ()=>{
    if(auth.currentUser!=null){
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.get(`${url}/user`,{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status===200){
            setusers(res.data.result)
          }
          else{
            alert('Permission denied!')
          }
        }).catch((err)=>{
          console.log(err)
        })
      }).catch((err)=>{
        console.log(err)
      })
    }
    else{
      alert('not logged in!')
    }
  }
  useEffect(() => {
    getUsers()
    const min = 100000; // Minimum value (inclusive)
    const max = 999999; // Maximum value (inclusive)
    setiotp(Math.floor(Math.random() * (max - min + 1)) + min)
  }, [])

  
  const stepToEmailVerify = ()=>{
    if(nuname.length<3){
      toast.error('Enter Name')
    }
    else{
      if(nupos.length<3){
        toast.error('Enter Position')
      }
      else{
        if(String(nuemail).includes('@')){
          if(String(nupass).length>6){
            if(String(nurepass).length>6){
              if(String(nupass)==String(nurepass)){
                if(auth.currentUser!=null){
                  auth.currentUser.getIdToken(true).then(token=>{
                    axios.post(`${url}/email`,{
                      otp:iotp,
                      email:nuemail
                    },
                    {
                      headers:{
                        'Authorization':token
                      }
                    }
                    ).then((resem)=>{
                      if(resem.status==200){  
                        setstep((p)=> p+1)
                      }
                    }).catch((err)=> console.log(err))
                  })
                  
                }
                else{
                  toast.error('user has been logged out. please refresh the page')
                }
                
               
              }
              else{
                toast.error("Password doesnot match")
              }
            }
            else{
              toast.error('Please retype the password')
            }
          }
          else{
            toast.error('Password must container more than 6 characters')
          }
        }
        else{
          toast.error("Please Enter a Valid Email Address")
        }
      }
    }
    
  }

  const verfyOTP = () =>{
    if(otp.length<6){
      toast.error('Enter the full OTP')
    }
    else{
      console.log('otp',otp)
        console.log('iotp',iotp)
      if(String(otp)==String(iotp)){
        
        
        setstep((p)=> p+1)
      }
      else{
        toast.error('you have entered the wrong OTP')
      }
    }
  }

  const uploadImage = (e)=>{
    const form = new FormData()
    form.append("material",e.target.files[0])
    axios.post('https://api.adsecbd.com/file',form).then((res)=>{
      if(res.status==200){
        setimglink(res.data.url)
        setimgup(true)
      }
    }).catch(err=> console.log(err))
  }
  const stepToAccess = ()=>{
    if(imglink && imgup){
      setstep((p)=> p+1)
    }
    else{
      toast.error("Upload an image first")
    }
  }

  const accessHandle = (code)=>{
  
    if(!accarr.includes(code)){
      setaccarr((p)=> [...p,code])
    }
    else{
      setaccarr(accarr.filter((v)=> v!=code))
    }
  }
  

  const createAccount = ()=>{
    if(auth.currentUser!=null){
      auth.currentUser.getIdToken(true).then((token)=>{
        axios.post(`${url}/create`,{
          email:nuemail,
          pass:nupass,
          name:nuname,
          position:nupos,
          contract:nucontact,
          access:accarr,
          photo:imglink
        },{
          headers:{
            'Authorization':token
          }
        }).then((res)=>{
          if(res.status==200){
            setstep((p)=> p+1)
          }
        })
      }).catch((err)=> console.log(err))
    }
  }

  const setDone = ()=>{
      setnuname('')
      setnuemail('')
      setnucontact('')
      setnupos('')
      setnurepass('')
      setimglink('')
      setshow(false)
      setimgup(false)
      getUsers()
      setuserdia(false)
      setstep(0)
  }
  return (
    <>
    <Toaster  position="bottom-right"
  reverseOrder={true}/>
    <div className='grid grid-cols-3'>
      {users&&users.map((v,i)=> <AccessCard key={i} name={v.userName} image={v.photo} obj={v}/>)}
    </div>
    <button className='ring-1 ring-theme-orange rounded-full px-5 py-3 bg-dashboard-dark-black text-white m-3' onClick={e=> setuserdia(true)}>Add New User</button>
    <Dialog
        fullScreen
        open={userdia}
        onClose={e=> setuserdia(p=> !p)}
        TransitionComponent={Transition}
        
      >
        <AppBar sx={{ position: 'relative'}}>
          <Toolbar sx={{background:'#111'}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={e=> {setuserdia(false);setstep(0)}}
              aria-label="close"
            >
              <Close/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add a New User
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="bg-[#333] min-h-full">
          {step==0&&<div>
          <h1 className="text-center text-3xl mt-5 text-white font-medium">Add a new user</h1>
          <div className="grid grid-cols-2 px-20">
            <div className="p-3">
              <p className='text-white py-2'>User's Full Name</p>
              <input type="text" value={nuname} onChange={e=> setnuname(e.target.value)} className='w-full ring-1 ring-white rounded-md  py-3 px-2'/>
            </div>
            <div className="p-3">
              <p className='text-white py-2'>User's Positional Status</p>
              <input type="text" value={nupos} onChange={e=> setnupos(e.target.value)} className='w-full ring-1 ring-white rounded-md  py-3 px-2'/>
            </div>
            <div className="p-3">
              <p className='text-white py-2'>Password</p>
              <input type={show?"text":"password"} value={nupass} onChange={e=> setnupass(e.target.value)} className='w-full ring-1 ring-white rounded-md  py-3 px-2'/>
            </div>
            <div className="p-3">
              <p className='text-white py-2'>Retype Password</p>
              <input type={show?"text":"password"} value={nurepass} onChange={e=> setnurepass(e.target.value)} className='w-full ring-1 ring-white rounded-md  py-3 px-2'/>
            </div>
            <div className="md:col-span-2 flex px-3">
              <input type="checkbox" onChange={e=> setshow(e.target.checked)}/> <p className='text-white px-2 font-light text-sm'>Show Password</p>
            </div>
            <div className="p-3">
              <p className='text-white py-2'>E-mail Address</p>
              <input type="text" value={nuemail} onChange={e=> setnuemail(e.target.value)} className='w-full ring-1 ring-white rounded-md  py-3 px-2'/>
            </div>
            <div className="p-3">
              <p className='text-white py-2'>Contact Number</p>
              <input type="text" value={nucontact} onChange={e=> setnucontact(e.target.value)} className='w-full ring-1 ring-white rounded-md  py-3 px-2'/>
            </div>
            <div className="md:col-span-2 flex justify-center">
                <button className='bg-theme-orange rounded-full px-5 w-48 mt-5 py-3 font-bold' onClick={stepToEmailVerify}>Next</button>
            </div>
          </div>
          </div>}
          {step==1&&<div className='grid grid-cols-2 h-full items-center'>
            <div className="h-full flex items-center justify-center flex-col">
            <img src="https://s3-alpha-sig.figma.com/img/0f26/76b4/672e4220940308d827cf7106205c1641?Expires=1688342400&Signature=iv2APjbgWazFBbpN7ZbWP90ZYJi7RYrmqq6ZWPwWR6JDCmix-zJtHMxSJNkd5u96dNHU4BB7DMFsfdaaTq8B9LYQBYZyK4cl-4kpKstMXBCv6SgPGpHqKxuewmxnOHQpzqrgnWAaWk56E0ON4ep5jUeds5wGFJSA4zQ0e1ACh~1Dzy8Iqdc6qflPA8D4YZY4-jHhs8dmcU4fVF-nCdPy0hdWpGoEa6i1MmsDo5e9nPX0tCaP1GAsKPasqu8v9IlL4C~3NXgbWmBhlp5D3w2rfcoeNK8yvsCFD2bYG14JPaBZxzXtD0FT~nEK7Y8~BgO0mUAixIPkwlBiB-viYFPPaA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" height="200px" width="200px"/>
            <p className='text-white text-3xl uppercase font-semibold mb-2'>Verify Email</p>
            <p className='text-white'>6-Digit OTP has been sent to your email address</p>
            <p className='text-sm text-theme-orange'>{nuemail}</p>
            </div>
            <div className="flex items-center flex-col justify-center">
            <OTPInput
              value={otp}
              onChange={setotp}
              inputType='password'
              inputStyle={{height:60,width:60,borderRadius:10,fontSize:50}}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className="w-full flex justify-center">
            <button className='bg-theme-orange rounded-full px-5 w-48 mt-5 py-3 font-bold' onClick={verfyOTP}>Next</button>
            </div>
            </div>
          </div>}
          {step==2&&<div className='grid grid-cols-2 h-full items-center'>
            <div className="h-full flex items-center justify-center flex-col">
              <img src="https://s3-alpha-sig.figma.com/img/3882/7999/267aec1fc0e3565c576fd63e3036f427?Expires=1688342400&Signature=m96mjx98NnOkxfiwCxaTVEMtwkHX8jmRzKEoeO4zZB7eaDmIz75zW6wVp64YRli33~khDRRwKp~r4zmnpcbWDvfPFIwSacVR240GZOeiR9iYzGWMKf5Lz1fj4GXtMbA0Dq6R489ayKTkZtBrbvAoU4oYmpLLPged9YSNMaPbn2y93-NzbSbUL3fEzL7NQzdXNUbEeVoWiALivzfDBaKgcBfP8HrU58LbGs0d2nMDZJU~j~~o4olQ6edfnfP8TK8RVsvNqu8RZAEBy8-uN1Cg1VHs1riY9Rmp08IVU6HJXgaNUyEsU3NfazP3TEd~jFpX-NAVS2c2fB7miA8beAFsFQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" width="200px" height="200px"/>
              <p className='text-white text-center font-semibold text-xl uppercase'>Upload user’s Formal Photo </p>
            </div>
            <div className="h-full flex items-center justify-center flex-col">
              {imglink&&<img src={imglink} className='rounded-md mb-3' height="auto" width="300px"/>}
              <button className='w-80 bg-white py-3 px-5 rounded-md flex items-center relative justify-center [&>*]:mx-2'><UserIcon h={20} w={20}/> {imgup?"Image Uploaded":"Click here to upload"}  <input type="file" onChange={uploadImage} style={{position: 'absolute', top: 0,left: 0,width: '100%',height:'100%',opacity: 0}}></input></button>
              <button className='bg-theme-orange rounded-full px-5 w-48 mt-5 py-3 font-bold' onClick={stepToAccess}>Next</button>
            </div>
          </div>}
          {step==3&&<div className='flex flex-col items-center justify-center'>
                <img src="https://s3-alpha-sig.figma.com/img/c310/4371/6899b939e32ee88a3e2ea6c7d43b743c?Expires=1688342400&Signature=qRAbG9WuKlhQIFO58Zjrr0yf8NUmPx68gud3AifzxSobHXdz~0RhbLdx7hYoVCCyICcPDJ7pkHC2fkdRrrzkQiogomy2UxKKSX9XqsK7JEEc3ZDlW9KCC9aPvwnxK3f6Vh7lhf4xbNWV1D4p-gXY1ysU7mNCLQ1a6k94U7KIAE-B5EXPeck1AGJ~PU4fTDCWmGnlBM7a2KFMPaFbLlYLKGxWZ07eZGS-hPaWB0zzJzy9dQ8-SxUyfZDCVw9ZB~G9IWg5AJmr4CycM7OjQJuYhl72Pc6Yomd35pniHV8w0eJBBpwCCtW4umYiaEcsprFrARaxcbBZTIrEuFS9e7sAEw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" width="200px" height="200px" className='my-3'/>
                <p className="text-center text-white uppercase mb-3 text-xl font-semibold">sELECT aCCESS</p>
                <div className="grid md:grid-cols-3">
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(230)} val={accarr.includes(230)} text="Access control ability"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(200)} val={accarr.includes(200)} text="Can upload blog"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(201)} val={accarr.includes(201)} text="Can manage blogs"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(202)} val={accarr.includes(202)} text="Access to blog archives"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(203)} val={accarr.includes(203)} text="Access to blog recycle-bin"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(204)} val={accarr.includes(204)} text="Access to Spreadsheets"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(205)} val={accarr.includes(205)} text="Can generate Auto Certificate"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(206)} val={accarr.includes(206)} text="Can manage images"/>
                  </div>
                  <div className="p-3">
                    <AccessSwitch setval={()=> accessHandle(207)} val={accarr.includes(207)} text="Can send bug reports"/>
                  </div>
                  
                </div>
                <center>
                <button className='bg-theme-orange rounded-full px-5 w-48 mt-5 py-3 font-bold' onClick={createAccount}>Next</button>
                </center>
          </div>}
          {step==4&&<div className='grid grid-cols-2 items-center h-full'>
            <div className="flex flex-col items-center justify-center">
              <img src="https://s3-alpha-sig.figma.com/img/bb83/5d8b/058b81eb80e6b10a288ec03a00a5540b?Expires=1688342400&Signature=XvPBwtwUPIYo~u9ANhiY878pXE~zEtl-YDyAYiTmyUfDmc5EesIlLb3nZpczjk8jZj~MUZxR6bzJMJwbinUKZb-66z5muEjCwJ5Yl9j10FA6fuGhTtY1aArFPNfEz5tfWF1TSLsQZVmAG3Nx3FSJuomKSRurpzYb9y6lf747B54xdo8WmX3FwFF1g1M0wzkJrfw0Siz568DaunK6Dpgo51EaULsYSVrztc6sl6ce-TuoQ3eaPoh78buswjJY-Eu8PNUXMTwc7jwWM9pyY2w4V339GL1Kcnf2WY93YuKvcwPYNVReZRLm4E7-qlctYnIyUR173f8kUckGG7JcJWjtxw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" height="200px" width="200px" alt="" />
              <p className="text-3xl font-semibold text-theme-orange uppercase">Congratulations</p>
              <p className="text-white my-2">New user created successfully </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-white">E-mail: <span className='text-theme-orange'>{nuemail}</span></p>
              <p className="text-white mt-2">Password: <span className='text-theme-orange'>{nupass}</span></p>
              <button className='bg-theme-orange rounded-full px-5 w-48 mt-5 py-3 font-bold' onClick={setDone}>Done</button>
            </div>
            </div>}
        </div>
      </Dialog>

      
    </>
  )
}

export default AccessControl