import React from 'react'
import { useEffect } from 'react'
import './LoginPage.css'
import mainAuth from '../../firebase'
import Logos from '../../icons/Logos'
import { useState } from 'react'
import AdminPanel from '../../icons/AdminPanel'
import { signInWithEmailAndPassword } from 'firebase/auth'
import apiurl from '../../apiurl'
import axios from 'axios'
const LoginPage = ({auth,setauth,setuid}) => {
  const [email, setemail] = useState('')
  const [pass, setpass] = useState('')
  const [err, seterr] = useState('')
  const [forget, setforget] = useState(false)
  const [forgeterr, setforgeterr] = useState('')
  const [forgetemail, setforgetemail] = useState('')
  useEffect(() => {
    if(mainAuth.currentUser!=null){
      setauth(true)
      setuid(mainAuth.currentUser.uid)
      console.log('init')
    }
  }, [])
  
  const login  = ()=>{
    if(!email){
      seterr('Please Enter Email Address')
    }
    else if(!pass){
      seterr('Please Enter Password')
    }
    else if(!email.includes('@')){
      seterr('Enter a Valid Email Address')
    }
    else if(pass.length<6){
      seterr('Password cannot contain less than 6 characters')
    }
    else{
      signInWithEmailAndPassword(mainAuth,email,pass).then((user)=>{
        setuid(user.user.uid)
        setauth(true)
      }).catch((err)=>{
        let errorMessage;
        switch (err.code) {
          case "auth/invalid-email":
            errorMessage = "Invalid email address.";
            break;
          case "auth/user-disabled":
            errorMessage = "User account is disabled.";
            break;
          case "auth/user-not-found":
            errorMessage = "User not found.";
            break;
          case "auth/wrong-password":
            errorMessage = "Invalid password.";
            break;
          default:
            errorMessage = "An error occurred. Please try again.";
            break;
        }
        seterr(errorMessage)
      })
    }
  }
  const passwordReset = ()=>{
    if(forgetemail){
      axios.post(`${apiurl}/reset`,{
        email:forgetemail
      }).then((res)=>{
        setforgetemail('')
        alert('Check Your Email!')
        setforgeterr('')
      }).catch((err)=>{
        setforgeterr(err.response.data.err.message)
      })
    }
    else{
      setforgeterr('Please Enter the email')
    }
    
  }
  useEffect(() => {
    seterr('')
  }, [email,pass])
  
  return (
    <div className='fixed top-0 left-0 bottom-0 right-0 w-full h-full mainContainer'>
      <div className="p-10 bg-black bg-opacity-[0.85] w-full h-full">
      <div className="fixed left-0 top-0 p-10">
        <Logos w={100}/>
      </div>
      <div className="h-full w-full flex items-center justify-center">
        {forget ? <div className='flex flex-col items-center'>
          <AdminPanel w={200}/>
          <div className="my-5">
            <p className="text-left text-theme-orange uppercase">Forget Password?</p>
            <input type="text" value={forgetemail} onChange={e=> setforgetemail(e.target.value)} className='ring-1 ring-white p-3 rounded-md mt-2 text-sm w-80' placeholder='E-mail'/>
            <p className='text-gray-300 text-sm mt-2 w-80'>Enter your email here and press the continue button. You will receive an email to reset your password</p>
            {forget && <p className="text-red-300 text-xs my-2 w-80 font-light text-center">{forgeterr}</p>}
            <div className="flex justify-end mt-2">
              <button className='transition-all ease-in-out duration-300 bg-theme-orange hover:bg-black hover:text-white text-black px-5 py-3 rounded-lg' onClick={passwordReset}>Continue</button>
            </div>
            <button className='text-gray-300 text-sm' onClick={e=> setforget(p=> !p)}>Back to Login</button>
          </div>
          

        </div> : <div className="">
          <center>
            <AdminPanel w={200}/>
            </center>
            <div className="mt-10">
              <label htmlFor="email" className='text-xs text-white uppercase'>Admin Email</label> <br />
              <input type="email" value={email} onChange={e=> setemail(e.target.value)} className={`ring-1 rounded-lg ring-white text-sm py-3 px-2 w-80`} id="email"/>
            </div>
            <div className="mt-10">
              <label className='text-xs text-white uppercase'>Password</label> <br />
              <input type="password" onChange={e=> setpass(e.target.value)} value={pass} className={`ring-1 rounded-lg ring-white text-sm py-3 px-2 w-80`}/>
            </div>
            <div className="flex justify-end">
            <button className="text-gray-300 text-xs mt-2 uppercase" onClick={e=> setforget(p=> !p)}>Forgot Password</button>
            </div>
            <center>
              <button className='px-16 mt-5 py-2 rounded-full transition-all ease-in-out duration-500 bg-theme-orange uppercase hover:bg-black hover:text-white text-black' onClick={login}>Login</button><br />
              <label htmlFor="email" className='text-xs text-red-300'>{err}</label> 
            </center>
        </div>}
        
      </div>
      </div>
    </div>
  )
}

export default LoginPage