import React, { useState } from 'react'
import Items from '../../../../Components'
import { useDrag } from 'react-dnd'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import { addElement } from '../../../../../../app/slices/blogSlice'
import HTMLCompo from './HTMLCompo'

const HTML = ({text}) => {
  const [open, setopen] = useState(false)
  const [html, sethtml] = useState('')
  const dis = useDispatch()
  let blogs = useSelector(state=> state.blog)
    const [{ opacity }, dragRef] = useDrag(
        () => ({
          type: Items.TOOL,
          item: { text },
          end:(item,monitor)=>{
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
              setopen(true)
            }
          },
          collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1
          })
        }),
        []
      )
      const addElem = ()=>{
          dis(addElement({id:Array.from(blogs).length+1,html,code:704,compo:<HTMLCompo id={Array.from(blogs).length+1} html={html}/>}))
          setopen(false)
      }
      
  return (
    <button className='flex flex-col items-center' ref={dragRef} style={{ opacity}}>
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      fill="none"
      viewBox="0 0 46 46"
    >
      <circle cx="23" cy="23" r="23" fill="#FFF27C"></circle>
      <path
        fill="#0D0D0D"
        d="M14 32v-3h-3v3H9.5v-7.5H11v3h3v-3h1.5V32H14zm4.5 0v-6h-2.1v-1.5h5.55V26H20v6h-1.5zm4.5 0v-7.5h1.5l2.1 5.1h.15L29 24.5h1.5V32H29v-4.65h-.15L27.2 31.1h-.9l-1.65-3.75h-.15V32H23zm13.5 0H32v-7.5h1.5v6h3V32zM22.1 14.3l-3.45 3.45 3.45 3.45-.9 1.8-5.25-5.25 5.25-5.25.9 1.8zm1.8 6.9l3.45-3.45-3.45-3.45.9-1.8 5.25 5.25L24.8 23l-.9-1.8z"
      ></path>
    </svg>
 <p className='text-center text-xs text-theme-orange uppercase mt-2'>HTML</p>
 <Dialog open={open} onClose={e=> setopen(false)} aria-labelledby="">
   <DialogTitle className='bg-dashboard-black text-theme-orange'>
     Add HTML
   </DialogTitle>
   <DialogContent className='bg-dashboard-dark-black'>
     <DialogContentText>
       <p className="text-xs text-gray-500 italic py-3">*Write the HTML Code and press the add button to add HTML</p>
       <label htmlFor="#html" className='text-sm text-theme-orange'>Write HTML</label>
       <textarea type="text" id='html' className='ring-1 w-full ring-theme-orange rounded-xl p-3 text-white bg-transparent' value={html} onChange={e=> sethtml(e.target.value)} rows={10}></textarea>
     </DialogContentText>
   </DialogContent>
   <DialogActions className='bg-dashboard-dark-black'>
    <ButtonGroup variant="contained" aria-label="">
      <Button color="success" onClick={addElem}>Add</Button>
      <Button color="error" onClick={e=> setopen(false)}>Cancel</Button>
      
    </ButtonGroup>
   </DialogActions>
 </Dialog>
 </button>
  )
}

export default HTML