
function ReportBug({h,w,selected}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    fill="none"
    viewBox="0 0 45 45"
  >
    <rect width="45" height="45" className="transition-all duration-300 ease-in-out" fill={selected?"#FFF27C":"#313131"} rx="10"></rect>
    <path
      fill={selected?"#313131":"#FFF27C"} className="transition-all duration-300 ease-in-out"
      d="M23 34.25c-1.375 0-2.635-.344-3.781-1.031A7.724 7.724 0 0116.5 30.5h-2.281c-.354 0-.646-.12-.875-.36a1.236 1.236 0 01-.344-.89c0-.354.12-.65.359-.89.24-.24.537-.36.891-.36h1.375c-.063-.48-.099-.89-.109-1.235-.01-.343-.016-.765-.016-1.265h-1.281c-.354 0-.646-.12-.875-.36a1.236 1.236 0 01-.344-.89c0-.354.12-.651.359-.891.24-.24.537-.359.891-.359h1.25c0-.563.005-1.026.016-1.391a8.09 8.09 0 01.109-1.109h-1.406c-.354 0-.646-.12-.875-.36a1.236 1.236 0 01-.344-.89c0-.354.12-.651.359-.891.24-.24.537-.359.891-.359h2.25c.292-.5.62-.953.985-1.36a6.348 6.348 0 011.265-1.078l-1.156-1.187a1.214 1.214 0 01-.328-.891c.01-.344.13-.63.359-.859.23-.23.52-.344.875-.344.354 0 .646.115.875.344l1.813 1.813c.479-.167 1.072-.25 1.78-.25.71 0 1.303.083 1.782.25l1.875-1.844c.25-.23.547-.339.891-.329.344.01.63.13.859.36.23.23.344.52.344.875 0 .354-.115.646-.344.875l-1.188 1.188c.48.312.912.671 1.298 1.077.385.407.723.86 1.015 1.36h2.281c.354 0 .646.12.875.359.23.24.344.537.344.891s-.12.65-.36.89-.536.36-.89.36h-1.375c.063.48.1.875.11 1.188.01.312.015.75.015 1.312h1.281c.354 0 .646.12.875.359.23.24.344.537.344.891s-.12.65-.36.89-.536.36-.89.36H30.5c0 .5-.005.922-.015 1.265-.01.344-.047.756-.11 1.235h1.406c.354 0 .646.12.875.36.23.24.344.536.344.89s-.12.65-.36.89-.536.36-.89.36H29.5a7.724 7.724 0 01-2.719 2.719c-1.146.687-2.406 1.031-3.781 1.031zM21.75 28h2.531c.354 0 .646-.12.875-.36.23-.24.344-.536.344-.89s-.12-.651-.359-.891a1.213 1.213 0 00-.891-.359h-2.531c-.354 0-.646.12-.875.359a1.24 1.24 0 00-.344.891c0 .354.12.65.36.89s.536.36.89.36zm0-5h2.531c.354 0 .646-.12.875-.36.23-.24.344-.536.344-.89s-.12-.651-.359-.891a1.213 1.213 0 00-.891-.359h-2.531c-.354 0-.646.12-.875.359a1.24 1.24 0 00-.344.891c0 .354.12.65.36.89s.536.36.89.36z"
      ></path>
  </svg>
  );
}

export default ReportBug;
